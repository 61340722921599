import { Grid } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"
import FormHeader from "../../components/form-header-component"
import FormField from "../../components/fields/form-field"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import GoogleMapsField from "../../components/fields/google-maps-field"
import { opponentsRoute } from "../../utils/page-routes"
import { useIsAdmin } from "../../utils/user-hooks"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathDeleteOpponent, apiPathGetOpponent, apiPathPostOpponent, apiPathUpdateOpponent } from "../../utils/endpoint-paths"
import useDoNavigate from "../../utils/do-navigate"
import { useSearchParams } from "react-router-dom"
import { all, equals, complement, isEmpty } from "ramda"
import { newFormEntityId } from "../../utils/constants"
import PageActionsStack from "../../components/buttons/page-actions-stack"
import { darkBlueButtonColor, redButtonColor } from "../../styles/style-constants"
import ButtonComponent from "../../components/button-component"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BaseDialog from "../../components/dialog-component"
import { Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

const EditOpponentPage = () => {


    
    const doNavigate = useDoNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const updateTarget = formEntityId => setSearchParams((prev) => ({...prev, target:formEntityId}))

    const opponentId = searchParams.get("target")
    const isCreatingNew = equals(opponentId, newFormEntityId)
    const clubId = useSelector(selectCurrentClubId)

    

    const { doGet, generateEndpoint, doPost, doPut, uploadPfp, doDelete } = useApiUtilsContext()

    const createOpponentEndpoint = generateEndpoint(apiPathPostOpponent(clubId))
    const updateOpponentEndpoint = generateEndpoint(apiPathUpdateOpponent(opponentId))
    const getOpponentEndpoint = generateEndpoint(apiPathGetOpponent(opponentId))
    const deleteOpponentEndpoint = generateEndpoint(apiPathDeleteOpponent(opponentId));
    const canEdit = useIsAdmin()

    const [snackbarLoading, setSnackbarLoading] = useState(false)


    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [imageId, setImageId] = useState()
    const [imageFormData, setImageFormData] = useState()
    const [location, setLocation] = useState()
    const [clubContactNumber, setClubContactNumber] = useState("")
    const [clubContactEmail, setClubContactEmail] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [contactPersonNumber, setContactPersonNumber] = useState("")
    const [contactPersonEmail, setContactPersonEmail] = useState("")
    const [openDialog, setOpenDialog] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const canSubmit = all(complement(isEmpty))([name, location, clubContactNumber, clubContactEmail])

    const getData = async () => {
        try {
            const response = await doGet({ endpoint: getOpponentEndpoint })
            const data = response?.data ?? {}
            setName(data?.details?.name)
            setDescription(data?.details?.description)
            setImageId(data?.details?.pictureId)
            setLocation(data?.location)
            setClubContactNumber(data?.mobile)
            setClubContactEmail(data?.email)
            setContactPersonEmail(data?.mainContact?.email)
            setContactPersonName(data?.mainContact?.name)
            setContactPersonNumber(data?.mainContact?.mobile)

        } catch (error) {
            console.error(error)
        }
    }

    const onSubmit = async () => {
        setSnackbarLoading(true)
        try {
            let pictureId = imageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }
            const body = {
                details: {
                    pictureId,
                    name,
                    description
                },
                email: clubContactEmail,
                mobile: clubContactNumber,
                mainContact: {
                    name: contactPersonName,
                    mobile: contactPersonNumber,
                    email: contactPersonEmail
                },
                location: location ?? {}
            }

            if (isCreatingNew) {

                const response = await doPost({ endpoint: createOpponentEndpoint, body })
                const newLocation = response?.headers?.location

                const newItemResponse = await doGet({ endpoint: newLocation })
                updateTarget(newItemResponse?.data?.id)
            } else {

                await doPut({ endpoint: updateOpponentEndpoint, body })

            }


        } catch (error) {
            console.error(error)
        }
        doNavigate(opponentsRoute)
        setSnackbarLoading(false)
    }

    const onCancel = async () => {
        if (!isCreatingNew) {
            await getData()
        }
        doNavigate(opponentsRoute)
    }

    useEffect(() => {
        
        if (clubId && !isCreatingNew) {
            getData()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onDelete = async () => {
      try {
        setIsDeleteLoading(true)
        await doDelete({endpoint: deleteOpponentEndpoint })
        doNavigate(opponentsRoute);
      } catch (error) {
        setIsDeleteLoading(false);
        console.log(error);
      }
    };

    return <BasePageComponent
        pageTitle={"Opponents"}
        backRoute={opponentsRoute}
        inlineContent={<PageActionsStack saveAction={onSubmit} cancelAction={onCancel} disableSave={!canSubmit} deleteComponent={ 
            !isCreatingNew && (<ButtonComponent
              title={"Delete Opponent"}
              icon={<DeleteOutlinedIcon />}
              background={darkBlueButtonColor}
              onClick={() => setOpenDialog(true)}
            />)
          }/>}
        snackbarLoading={snackbarLoading}
    >
        <DeleteOpponentDialog openDialog={openDialog} setOpenDialog={setOpenDialog} handleDelete={onDelete} isDeleteLoading={isDeleteLoading}/>

        <CardContainer padding="20px">
            <Grid container rowSpacing={5} columns={2}>
                <Grid item xs={2}>
                    <FormHeader
                        label={"Club Name"}
                        title={name}
                        setTitle={setName}
                        description={description}
                        setDescription={setDescription}
                        minioId={imageId}
                        setMinioId={setImageId}
                        setFormData={setImageFormData}
                        readOnly={!canEdit}
                    />
                </Grid>
                <Grid item xs={2}>
                    <GoogleMapsField label="Location" value={location} setValue={setLocation} readOnly={!canEdit} mandatory={true}/>
                </Grid>

                <Grid item xs={2} sm={1}>
                    <FormField label={"Club Contact Number"} value={clubContactNumber} setValue={setClubContactNumber} readOnly={!canEdit} mandatory={true} />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Club Email Address"} value={clubContactEmail} setValue={setClubContactEmail} readOnly={!canEdit} mandatory={true}/>
                </Grid>
                <Grid item xs={2}>
                    <FormField label={"Contact Person"} value={contactPersonName} setValue={setContactPersonName} readOnly={!canEdit}/>
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Contact Person Number"} value={contactPersonNumber} setValue={setContactPersonNumber} readOnly={!canEdit}/>
                </Grid>
                <Grid item xs={2} sm={1}>
                    <FormField label={"Contact Person Email Address"} value={contactPersonEmail} setValue={setContactPersonEmail} readOnly={!canEdit}/>
                </Grid>
            </Grid>

        </CardContainer>

    </BasePageComponent>
}

export const DeleteOpponentDialog = ({
    openDialog,
    setOpenDialog,
    handleDelete,
    isDeleteLoading,
  }) => {
    return (
      <BaseDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={"Delete Opponent?"}
        titleProps={{ textAlign: "center", fontSize: "24px" }}
        content={
          <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
            <Typography
              color={"#3C3352"}
              letterSpacing={"2px"}
              fontSize={"14px"}
              fontWeight={"600"}
              textAlign={"center"}
            >
              Are you sure you want to delete?
            </Typography>
            <Typography
              color={"#3C3352"}
              letterSpacing={"2px"}
              fontSize={"14px"}
              fontWeight={"600"}
              textAlign={"center"}
            >
              (Please note, this action cannot be reversed)
            </Typography>
          </Stack>
        }
        actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
        paperProps={{
          sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
        }}
        actions={
          <Stack direction={"row"} gap={"25px"}>
            <ButtonComponent
              title={"Delete"}
              icon={<DeleteOutlinedIcon fontSize="small" />}
              background={darkBlueButtonColor}
              onClick={handleDelete}
              disabled={isDeleteLoading}
            />
            <ButtonComponent
              disabled={isDeleteLoading}
              title={"Cancel"}
              icon={<CloseIcon fontSize="small" />}
              background={redButtonColor}
              onClick={() => setOpenDialog(false)}
            />
          </Stack>
        }
      />
    );
  };

export default EditOpponentPage