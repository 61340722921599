import { NotificationsNone as NotificationsIcon } from "@mui/icons-material"
import { Badge, ButtonBase } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentClubId, selectUnreadNotificationCount, selectUserPersonId } from "../../redux/selectors"
import { useEffect } from "react"
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathNotificationList } from "../../utils/endpoint-paths"
import { notificationsRoute } from "../../utils/page-routes"
import { Link } from "react-router-dom"
import { updateUnreadNotificationCount } from "../../redux/app-slice"

const NotificationAppBarButton = () => {

    const personId = useSelector(selectUserPersonId)
    const clubId = useSelector(selectCurrentClubId)
    const notificationCount = useSelector(selectUnreadNotificationCount)

    const { generateEndpoint, doGet } = useApiUtilsContext()

    const getNotificationsEndpoint = generateEndpoint(apiPathNotificationList(personId))

    const dispatch = useDispatch()

    const getNotifications = async () => {
        let queryParams = {
            club_id: clubId,
            read: false
        }

        try {
            const response = await doGet({ endpoint: getNotificationsEndpoint, queryParams })
            dispatch(updateUnreadNotificationCount(response?.data?.length ?? 0))
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {

        if(personId) {
            getNotifications()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personId])

    return <ButtonBase component={Link} to={notificationsRoute}>
        <Badge color="error" badgeContent={notificationCount}>
            <NotificationsIcon />
        </Badge>
    </ButtonBase>


}

export default NotificationAppBarButton