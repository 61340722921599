
import { Grid, Typography, Stack } from "@mui/material"
import InitialOnboardingPageTwo from "./initial-page-two"
import PhotoBox from "../../components/photo-box-component"
import { useSelector } from "react-redux"
import { selectCurrentClubName } from "../../redux/selectors"

const OnboardingPageOne = ({ clubImageId, memberId, clubId, isMobile, setQualList, qualList, selectedQuals, setSelectedQuals }) => {

    const clubName = useSelector(selectCurrentClubName)

    return <InitialOnboardingPageTwo
        header={<Grid container columns={3}>
            <Grid item xs={2}>
                <Stack direction={"column"} spacing={3}>
                    <Typography fontWeight={500} fontSize="24px" lineHeight="29.26px">
                        You have been invited to join {clubName}
                    </Typography>

                    <Typography fontSize={"14px"} color={"#323E59"}>
                        Please complete the following onboarding form to successfully join the club.
                    </Typography>

                    <Typography lineHeight={"12.19px"} fontSize={"10px"} color={"#323E59"}>
                        <span style={{ color: "#F41616" }}>*</span>Required
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={1}>
                <PhotoBox readOnly minioId={clubImageId} label="" />

            </Grid>
        </Grid>}
        memberId={memberId}
        clubId={clubId}
        isMobile={isMobile}
        qualList={qualList}
        setQualList={setQualList}
        selectedQuals={selectedQuals}
        setSelectedQuals={setSelectedQuals}
    />


}


export default OnboardingPageOne