import { Grid } from "@mui/material"
import { equals, map, head, last } from "ramda"


const days = [
    "SUN","MON","TUES","WED","THURS","FRI","SAT"
]

export const DayHeader = ({ day, isFirst, isLast }) => {

    return <Grid
        item
        xs={1}
        width={"100%"}
        sx={{
            borderLeft: isFirst ? "" : "solid 1px #F1F1F1",
            borderRight: isLast ? "" : "solid 1px #f1f1f1",
            fontFamily: "Montserrat",
            fontWeight: 500,
            background: "#C4C4C4",
            textAlign: 'center',
            paddingY: '5px',
            letterSpacing: '1.5px',
            textTransform: "uppercase"
        }}
    >
        {day}
    </Grid>

}

export const WeekHeader = () => map((day) => <DayHeader isFirst={equals(head(days), day)} isLast={equals(last(days), day)} key={day} day={day}/>)(days)