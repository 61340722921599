import { OutlinedInput, Stack } from "@mui/material"
import FieldLabel from "../field-label"
import ReadOnlyField from "../readonly-field"

const MultilineField = ({ label, id, value, setValue, spacing = 2, readOnly = false, maxWidth, minRows = 4, disabled = false, mandatory = false }) => {
    return <Stack direction="column" spacing={spacing}>
        <FieldLabel label={label} disabled={disabled} mandatory={mandatory} />
        {readOnly ? <ReadOnlyField value={value} /> :
            <OutlinedInput
                disabled={disabled}
                readOnly={readOnly}
                id={id}
                value={value ?? ""}
                onChange={(e) => setValue(e.target.value)}
                multiline
                minRows={minRows}
                variant="outlined"
                sx={{
                    maxWidth: maxWidth
                }}
            />}
    </Stack>
}

export default MultilineField