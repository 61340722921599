import BasePageComponent from "../../components/base-page-component"
import { getAppEnvironment, getAppName, getAppVersion } from "../../utils/envs"


const AboutPage = () => {

    return <BasePageComponent
        pageTitle={getAppName()}>
            <div style={{paddingLeft:"50px"}}>
            Version {getAppVersion()}-{getAppEnvironment()}
            </div>
            
    </BasePageComponent>
    
}

export default AboutPage