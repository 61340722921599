import { useEffect } from "react";


const useDebounce = (callback, time, dependancies=[]) => useEffect(() => {
    const timeout = setTimeout(() => {
        callback()
    }, time)

    return () => clearTimeout(timeout)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [...dependancies])



export default useDebounce

// useEffect(() => {
//     const setAgeFilters = setTimeout(() => {
//         setAgeFilter(ageSliderValue)

//     }, 500)

//     return () => clearTimeout(setAgeFilters)
// // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [...ageSliderValue])