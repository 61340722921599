import { Grid, Stack } from "@mui/material";
import BasePageComponent from "../../components/base-page-component";
import CardContainer from "../../components/card-container";
import { useState, useEffect } from "react";
import FormHeader from "../../components/form-header-component";
import FormField from "../../components/fields/form-field";
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathGetClub } from "../../utils/endpoint-paths";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentClubId } from "../../redux/selectors";
import SaveCancelStack from "../../components/buttons/save-cancel-stack";
import { updateUserClub } from "../../redux/app-slice";
import { dashboardRoute } from "../../utils/page-routes";
import GoogleMapsField from "../../components/fields/google-maps-field";
import { all } from "ramda";
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty";
import { useIsAdmin } from "../../utils/user-hooks";
import FieldLabel from "../../components/fields/field-label";
import { blueButtonColor } from "../../styles/style-constants";
import FloatFormField from "../../components/fields/float-form-field"
import DatePickerField from "../../components/fields/date-picker-field";
import dayjs from "dayjs";
import useDoNavigate from "../../utils/do-navigate";


const ClubDetailsPage = () => {

    const { generateEndpoint, doGet, doPut, uploadPfp } = useApiUtilsContext()

    const currentClubId = useSelector(selectCurrentClubId)
    const dispatch = useDispatch()
    const doNavigate = useDoNavigate()

    const isAdmin = useIsAdmin()

    const dispatchClubUpdate = details => dispatch(updateUserClub(details))

    const [loading, setLoading] = useState(false)

    const [clubName, setClubName] = useState("");
    const [clubImageId, setClubImageId] = useState();
    const [clubDescription, setClubDescription] = useState("");
    const [clubLocation, setClubLocation] = useState("");
    const [clubEmail, setClubEmail] = useState("");
    const [clubPhone, setClubPhone] = useState("");
    const [clubContactName, setClubContactName] = useState("");
    const [clubContactEmail, setClubContactEmail] = useState("");
    const [clubContactPhone, setClubContactPhone] = useState("");
    const [clubImageFormData, setClubImageFormData] = useState();
    const [clubHoursRequired, setClubHoursRequired] = useState()
    const [quotaStartDate, setQuotaStartDate] = useState()
    const [quotaEndDate, setQuotaEndDate] = useState()


    const getClubEndpoint = generateEndpoint(apiPathGetClub(currentClubId))

    const mandatoryValues = [clubName, clubLocation, clubEmail, clubPhone, clubHoursRequired, quotaStartDate, quotaEndDate]

    const canSave = all(isNotNullOrUndefinedOrEmpty)(mandatoryValues)


    const getData = async () => {
        try {
            const response = await doGet({ endpoint: getClubEndpoint })
            setClubName(response.data?.details?.name ?? "");
            // setClubPhoto(response.data?.);
            setClubDescription(response.data?.details?.description ?? "");
            setClubLocation(response.data?.location ?? "");
            setClubEmail(response.data?.email ?? "");
            setClubPhone(response.data?.mobile ?? "");
            setClubContactEmail(response?.data?.contact?.email)
            setClubContactName(response?.data?.contact?.name)
            setClubContactPhone(response?.data?.contact?.phone)
            setClubImageId(response?.data?.details?.pictureId)
            setClubHoursRequired(response?.data?.quota?.hoursRequired)
            setQuotaStartDate(dayjs(response?.data?.quota?.startDate))
            setQuotaEndDate(dayjs(response?.data?.quota?.endDate))
        } catch (error) {
            // set up error catching here
            console.error(error)
        }
    }

    useEffect(() => {

        if (currentClubId) {

            getData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId]);

    const onSave = async () => {
        setLoading(true)

        let pictureId = clubImageId
        if (clubImageFormData) {
            pictureId = await uploadPfp(clubImageFormData)
        }

        const body = {
            details: {
                name: clubName,
                description: clubDescription,
                pictureId,
            },
            contact: {
                name: clubContactName,
                email: clubContactEmail,
                mobile: clubContactPhone,
            },
            quota: {
                hoursRequired: clubHoursRequired,
                startDate:  quotaStartDate.format("YYYY-MM-DD"),
                endDate: quotaEndDate.format("YYYY-MM-DD")
            },
            location: clubLocation,
            email: clubEmail,
            mobile: clubPhone,

        }

        try {
            await doPut({ endpoint: getClubEndpoint, body })
            dispatchClubUpdate({ ...body, id: currentClubId })
        } catch (error) {
            console.error(error)
        }

        setLoading(false)

    }

    const onCancel = async () => {
        await getData()
        doNavigate(dashboardRoute)
    }

    return (

        <BasePageComponent
            pageTitle="Club Details"
            snackbarLoading={loading}
            inlineContent={isAdmin && <SaveCancelStack disableSave={!canSave} saveAction={onSave} cancelAction={onCancel} />}
        >
            <CardContainer padding="20px">

                <Stack spacing={5}>
                    <FormHeader
                        label="Club Name"
                        title={clubName}
                        setTitle={setClubName}
                        description={clubDescription}
                        setDescription={setClubDescription}
                        setMinioId={setClubImageId}
                        minioId={clubImageId}
                        setFormData={setClubImageFormData}
                        mandatory
                        readOnly={!isAdmin}
                    />
                    <Grid container columns={2} rowSpacing={5} >
                        <Grid item xs={2}>
                            <GoogleMapsField
                                id="club-location"
                                value={clubLocation}
                                setValue={setClubLocation}
                                label="Club Location"
                                placeholder="Location"
                                mandatory
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                id="club-email"
                                value={clubEmail}
                                setValue={setClubEmail}
                                label="Club Email"
                                placeholder="Email"
                                mandatory
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                id="club-phone"
                                value={clubPhone}
                                setValue={setClubPhone}
                                label="Club Phone"
                                placeholder="Phone"
                                mandatory
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                id="club-contact-name"
                                value={clubContactName}
                                setValue={setClubContactName}
                                label="Main Club Contact"
                                placeholder="Name"
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                id="club-contact-email"
                                value={clubContactEmail}
                                setValue={setClubContactEmail}
                                label="Main Club Contact Email"
                                placeholder="Email"
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormField
                                id="club-contact-phone"
                                value={clubContactPhone}
                                setValue={setClubContactPhone}
                                label="Main Club Contact Number"
                                placeholder="Phone"
                                readOnly={!isAdmin}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FieldLabel label={"Volunteer Quota"} color={blueButtonColor} />
                        </Grid>
                        <Grid item xs={2}>
                            <FloatFormField
                                mandatory
                                readOnly={!isAdmin}
                                label={"Volunteer Hours Required for each Member"}
                                value={clubHoursRequired}
                                setValue={setClubHoursRequired}
                                decoration={"Hrs"}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <DatePickerField
                                mandatory
                                label={"Quota Start Date"}
                                value={quotaStartDate}
                                readOnly={!isAdmin}
                                setValue={setQuotaStartDate}
                                maxDate={quotaEndDate}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <DatePickerField
                                mandatory
                                label={"Quota End Date"}
                                value={quotaEndDate}
                                readOnly={!isAdmin}
                                setValue={setQuotaEndDate}
                                minDate={quotaStartDate}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </CardContainer>
        </BasePageComponent>
    );
};

export default ClubDetailsPage;