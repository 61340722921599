import { CircularProgress, Stack, Typography } from "@mui/material"

const LoadingPage = ({ message }) => {

    return <Stack width={"100%"} height={"100vh"} alignItems={"center"} justifyContent={"center"} spacing={1}>
        <CircularProgress />
        <Typography fontSize={"14px"}
            lineHeight={"17px"}
            color={'#3C3352'}
            letterSpacing={"1.86px"}
            textAlign={"center"}
            width={"100%"}
            fontFamily={"Montserrat"} >{message}</Typography>
    </Stack>


}

export default LoadingPage