import { toUpper, toLower, type } from "ramda";

export const capitaliseFirstLetter = (text) => {
    if(type(text) === "String") {
        return toUpper(text[0]) + toLower(text).slice(1);
    } else {
        return text;
    }
};

export const capitaliseWords = (text) => {
    return text.split(' ')
        .map(word => capitaliseFirstLetter(word))
        .join(' ');
};