import CardContainer from "../../components/card-container"
import FormTableComponent from "../../components/form-table-component"
import { columnTypeBoolean } from "../../components/table-component"
import { append, equals, findIndex, includes, remove } from "ramda"
import FieldLabel from "../../components/fields/field-label"
import { blueButtonColor } from "../../styles/style-constants"
import { Typography } from "@mui/material"

const InitialOnboardingPageTwo = ({header, qualList, selectedQuals, setSelectedQuals }) => {

    const onUpdate = (qual, _, value) => {
        const qualId = qual?.id
        setSelectedQuals((prev) => {
            if (includes(qualId)(selectedQuals) && !value) {
                return remove(findIndex(equals(qualId))(prev),1)(prev)
            } else if (!includes(qualId)(selectedQuals) && value) {
                return append(qualId, prev)
            } else {
                return prev
            }
        })
    }

    return <CardContainer padding="20px" marginTop="15px">
        {header}
        <FieldLabel label={"Qualifications"} color={blueButtonColor}/>
        <Typography marginTop={2}>
        Do you hold any of the below qualifications? (Please select all that apply)
        </Typography>
        <FormTableComponent
            marginTop={2}
            readOnly
            items={qualList}
            onUpdate={onUpdate}
            columns={[
                {title:"Qualification Type", getValue: row => row?.details?.name},
                {title:"Description", getValue: row => row?.details?.description},
                {title:"", getValue: row => includes(row?.id)(selectedQuals), canEdit: true, type: columnTypeBoolean, readOnlyOverride:true}
            ]}
        />

    </CardContainer>


}


export default InitialOnboardingPageTwo