import { ButtonBase, Typography } from "@mui/material"
import { Link } from "react-router-dom"


const DrawerButton = ({ label, route, onClose }) => {

    const isCurrent = window.location.href.endsWith(route)


    return <ButtonBase component={Link} to={route} onClick={onClose}>
        <Typography
            fontWeight={700}
            textTransform={"uppercase"}
            fontSize={"14px"}
            lineHeight={"17px"}
            letterSpacing={"2px"}
            width={"100%"}
            sx={{
                opacity: isCurrent ? 1.0 : 0.4,
                padding: "5px",
                paddingLeft: 5
            }}
        >
            {label}
        </Typography>
    </ButtonBase >


}

export default DrawerButton