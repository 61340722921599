

export const newFormEntityId = "___NEW___"

export const memberTypeAdmin = "ADMIN"
export const memberTypeClubMember = "CLUB_MEMBER"
export const memberTypeCommunityMember = "COM_MEMBER"


export const statusVacant = "VACANT"
export const statusAssigned = "ASSIGNED"
export const statusAccepted = "ACCEPTED"
export const statusActive = "ACTIVE"
export const statusInactive = "INACTIVE"

export const attendeeStatusDeclined = "DECLINED"
export const attendeeStatusUnconfirmed = "UNCONFIRMED"
export const attendeeStatusConfirmed = "CONFIRMED"

export const statusList = [
    statusVacant,
    statusAssigned,
    statusAccepted
]



export const memberTypeDropdownOptions = [
    {value: memberTypeAdmin, label: "Admin"},
    {value: memberTypeClubMember, label:"Club Member"},
    {value: memberTypeCommunityMember, label:"Community Member"}
]