import { Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ButtonComponent from "../../button-component";
import { greenButtonColor, redButtonColor } from "../../../styles/style-constants";
import { isNotNullOrUndefinedOrEmpty } from "../../../utils/helper-functions/is-null-or-undefined-or-empty";

const PageActionsStack = ({saveAction, cancelAction, disableSave, disableCancel, deleteComponent = null }) => {
    return (
        <Stack spacing={2} direction="row">
            {!isNotNullOrUndefinedOrEmpty(null) && deleteComponent}
            <ButtonComponent disabled={disableSave} onClick={saveAction} title={"Save Changes"} icon={<AddIcon />} background={greenButtonColor}/>
            <ButtonComponent disabled={disableCancel} onClick={cancelAction} title={"Cancel"} icon={<CloseIcon />} background={redButtonColor}/>
        </Stack>
    )
};

export default PageActionsStack;