import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import ApiUtilsContextProvider from './providers/api-utils-provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
import CustomKeycloakProvider from './providers/custom-keycloak-provider';


const root = ReactDOM.createRoot(document.getElementById('root'));







root.render(
  <ReduxProvider store={store}>
    <CustomKeycloakProvider>
        <React.StrictMode>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ApiUtilsContextProvider>
              <App />
            </ApiUtilsContextProvider>
          </LocalizationProvider>
        </React.StrictMode>
    </CustomKeycloakProvider>
  </ReduxProvider>
);

reportWebVitals()

