import { Container, Drawer, Typography, Stack, ButtonBase, Box } from "@mui/material"
import { drawerWidth, primaryColor } from "../../styles/style-constants"
import UserProfileDrawerButton from "./user-profile-drawer-button"
import DrawerButton from "./drawer-button"
import { activityTypesRoute, dashboardRoute, eventTypesRoute, eventsRoute, membersRoute, notificationsRoute, opponentsRoute, qualificationsRoute, teamsRoute } from "../../utils/page-routes"
import { useHasNoClubs, useIsAdmin } from "../../utils/user-hooks"
import { reject } from "ramda"
import { useCustomKeycloak } from "../../providers/custom-keycloak-provider"

const DrawerComponent = ({ isOpen, onClose, isMobile }) => {

    const isAdmin = useIsAdmin()
    const hasNoClubs = useHasNoClubs()

    const { keycloak } = useCustomKeycloak()
    

    const buttonSetA = [
        { label: "Dashboard", route: dashboardRoute },
        { label: "Events", route: eventsRoute },
        { label: "Members", route: membersRoute, adminOnly:true },
        { label: "Teams", route: teamsRoute },
        { label: "Notifications", route: notificationsRoute },
    ]

    const adminButtons = [
        { label: "Event Types", route: eventTypesRoute },
        { label: "Activity Types", route: activityTypesRoute },
        { label: "Qualifications", route: qualificationsRoute },
        { label: "Opponents", route: opponentsRoute },
    ]

    const drawerButtons = reject((button) => !isAdmin && button?.adminOnly)(buttonSetA)



    return <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={onClose}
    >

        <Container sx={{ background: primaryColor, height: "100%", color: "white" }}>
            <Stack height="100%">
                <Stack height={"100%"} width={drawerWidth}>

                    <Typography paddingY={2} fontWeight={"bold"} textAlign={"center"} variant="h6" >
                       MemberClubTeams 
                    </Typography>
                    <Box paddingTop={2} />
                    <UserProfileDrawerButton onClose={onClose} />
                    <Box paddingTop={8} />
                    {!hasNoClubs && drawerButtons.map(({ label, route }) => {
                        return <DrawerButton key={`${label}${route}`} label={label} route={route} onClose={onClose} />
                    })}


                    <Box paddingTop={8} />
                    {(!hasNoClubs && isAdmin) &&
                        adminButtons.map(({ label, route }) =>
                            <DrawerButton key={`${label}${route}`} label={label} route={route} onClose={onClose} />

                        )
                    }
                </Stack>
                <ButtonBase padding={2} onClick={() => {
                    keycloak.logout()
                }}>
                    <Typography
                        color="white"
                        align="center"
                        padding={2}
                        fontWeight={700}
                        textTransform={"uppercase"}
                        fontSize={"14px"}
                        lineHeight={"17px"}
                        letterSpacing={"2px"}>
                        Logout
                    </Typography>
                </ButtonBase>

            </Stack>

        </Container>


    </Drawer>

}

export default DrawerComponent