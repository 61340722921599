import { Snackbar } from "@mui/material"
import { useEffect, useState } from "react"

const LoadingSnackBarComponent = ({
    loadingMessage = "Saving...", 
    doneMessage = "Saved!", 
    loading
}) => {
    const [open, setOpen] = useState(false)
    const [done, setDone] = useState(false)

    useEffect(() => {

        if (loading) {
            setOpen(true)
        } else if (open && !loading) {
            setDone(true)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return
        }
        setOpen(false)
        setDone(false)
        
    }


    return <Snackbar
        onClose={handleClose}
        autoHideDuration={done ? 3e3 : 30e3}
        message={done ? doneMessage : loadingMessage}
        open={open}
    />

}

export default LoadingSnackBarComponent