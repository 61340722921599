import { Typography } from "@mui/material"
import CardContainer from "../../components/card-container"
import FieldLabel from "../../components/fields/field-label"
import { blueButtonColor } from "../../styles/style-constants"
import FormTableComponent from "../../components/form-table-component"
import { assocPath, equals, filter, findIndex, includes, isEmpty, map, update } from "ramda"
import { columnTypeDate, columnTypeDocument } from "../../components/table-component"
import { useEffect } from "react"

const OnboardingUploadPage = ({qualList, selectedQuals, uploadedQuals, setUploadedQuals}) => {
    const tableQuals = filter((qual) => {
        return qual?.needsCertification && includes(qual?.id)(selectedQuals)
    })(qualList)

    const onUpdate = (qualification, path, value) => {
        
        const {id: qualId } = qualification
        const qualIndex = findIndex((item) => equals(item?.id)(qualId))(uploadedQuals)

        setUploadedQuals((prev) => update(qualIndex, assocPath(path, value, qualification))(prev))

    }

    useEffect(() => {
        if (isEmpty(uploadedQuals)) {

            setUploadedQuals(map(qual => {
                return {
                    id: qual?.id,
                    name: qual?.details?.name,
                    document: {},
                    expiry: {}
                }
            })(tableQuals))

        } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return <CardContainer padding="20px" marginTop="15px">
        <FieldLabel label={"Certifications"} color={blueButtonColor} />
        <Typography marginTop={2}>
            Please provide proof of certification (i.e. copy or picture of certificate of attainment) for the following qualifications:
        </Typography>
        <FormTableComponent
            marginTop={2}
            readOnly
            items={uploadedQuals ?? []}
            onUpdate={onUpdate}
            columns={[
                {
                    title:"Qualification Type", 
                    getValue: row => row?.name
                },
                { 
                    title:"Proof of Certification", 
                    readOnlyOverride:true, 
                    type: columnTypeDocument,
                    getValue: row => row?.document,
                    setPath: ["document"],
                },
                {
                    title:"Expiry Date", 
                    readOnlyOverride:true, 
                    type:columnTypeDate, 
                    setPath: ["expiry"],
                    getValue: row=>row?.expiry
                }
            ]}
        />
    </CardContainer>

}

export default OnboardingUploadPage