import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"

const NotFoundPage = () => {
    return <BasePageComponent pageTitle={"404: Not Found"}>
        <CardContainer>
            Sorry, the page you're trying to access does not exist!
        </CardContainer>
    </BasePageComponent>
}

export default NotFoundPage