import { Stack, Typography } from "@mui/material"
import { fieldWidth } from "../../../styles/style-constants"
import { TimePicker } from "@mui/x-date-pickers"
import FieldLabel from "../field-label"
import ReadOnlyField from "../readonly-field"
import dayjs from "dayjs"


const TimeRangeField = ({ disabled, startValue, setStartValue, endValue, setEndValue, spacing = 2, readOnly = false, mandatory = false }) => {


    const picker = (value, setValue, label, readOnly, isEnd, disabled) => {
        return <Stack direction={"column"} spacing={spacing}>
            <FieldLabel label={label} mandatory={mandatory} />
            {readOnly ? <ReadOnlyField value={value} parseValue={(value) => dayjs(value).format("h:mm A")} /> :
                <TimePicker
                    disabled={disabled}
                    readOnly={readOnly}
                    value={value ?? {}}
                    onChange={(value) => {
                        setValue(value)
                    }}
                    minTime={(isEnd && startValue) ? startValue : undefined}
                    maxTime={(!isEnd && endValue) ? endValue : undefined}
                    slotProps={{
                        textField: {
                            variant: "standard"
                        }
                    }} />
            }
        </Stack>
    }

    return <Stack direction={"row"} width={fieldWidth} spacing={2}>
        {picker(startValue, setStartValue, "Start", readOnly, false, disabled)}
        <Stack direction="column" justifyContent={"flex-end"} paddingBottom={"2px"}>
            <Typography fontWeight={600} fontSize={16} lineHeight={"17px"} letterSpacing={"2px"}>
                TO
            </Typography>
        </Stack>
        {picker(endValue, setEndValue, "End", readOnly, true, disabled)}
    </Stack>


}

export default TimeRangeField