import { Box, Stack } from "@mui/material"
import { statusColors } from "../../styles/style-constants"
import { useState } from "react"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"

const StatusCircleComponent = ({ status, count, size="25px", fontSize="14px", onMouseEnter, onMouseLeave }) => {

    const [hovered, setHovered] = useState(false)

    return <Stack
        position={"relative"}
        onMouseEnter={() => { 
            if (isNotNullOrUndefinedOrEmpty(onMouseEnter)) {
                setHovered(true)
                onMouseEnter()
            }
        }}
        onMouseLeave={() => {
            if (isNotNullOrUndefinedOrEmpty(onMouseLeave)) {
                setHovered(false)
                onMouseLeave()
            }
        }}
        sx={{
            borderRadius: "100%",
            background: statusColors[status],
            height: size,
            width: size,
            textAlign: "center",
            justifyContent: "center",
            color:"white",
            fontWeight: 700,
            fontSize:fontSize,
        }}
    > {count}
        {hovered &&
        <Box 
            position={"absolute"} 
            width={"100%"} 
            height={"100%"} 
            sx={{background:"#000000", opacity:"20%"}}
            borderRadius={"100%"}
            
        />}
    </Stack>
}

export default StatusCircleComponent