import { TextField, Autocomplete } from "@mui/material"
import { equals, identity, keys, map, reduce } from "ramda"
import { blueButtonColor } from "../../../styles/style-constants"


export const filterAll = "ALL"

export const createFilterParams = (items) => reduce((acc, elem) => {
    if (!equals(items[elem])(filterAll)) {
        acc[elem] = items[elem]
    }
    return acc
}, {}, keys(items))

const DropdownFilterField = ({ placeholder, value, setValue = identity, options = [] }) => {

    const mappedOptions = map(({value}) => {
        return value
    })(options)


    let lookup = reduce((acc, elem) => {
        acc[elem?.value] = elem?.label
        return acc
    },{})(options)
    lookup[filterAll] = `All ${placeholder}`

    const allOptions = [filterAll, ...mappedOptions]

    return <Autocomplete
    options={allOptions}
    getOptionLabel={(option) => lookup[option] ?? option}
    value={value ?? filterAll}
    sx={{
        width:"20ch",
        '& .MuiInputBase-root': {
            color: "rgb(0,0,0,0.65)",
            background: "white"
        },
        '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${blueButtonColor} !important`            
        },
        '& fieldset': {
            borderColor: `${blueButtonColor} !important` 
        }
    }}
    onChange={(event, newValue) => setValue(newValue)}
    renderInput={(params) => <TextField {...params} size="small" />}

/>

    // return <Select
    
    //     placeholder={placeholder}
    //     value={value ?? filterAll}
    //     onChange={handleChange}
    //     variant="standard"
    //     sx={{
    //         border:"solid 2px",
    //         borderRadius:"4px",
    //         borderColor: "#E0E0E0",
    //         width: "20ch",
    //         background:'white',
    //         marginRight:"2px"
    //     }}
    //     inputProps={{
    //         sx:{
    //             padding:"10px"
    //         }
    //     }}
    //     >
    //     <MenuItem value={filterAll}>All {placeholder}</MenuItem>
    //     {options.map(({ label, value }) =>
    //         <MenuItem key={value} value={value}>{label}</MenuItem>
    //     )}
    // </Select>
}

export default DropdownFilterField