import dayjs from "dayjs"
import { endsWith, is, split } from "ramda"
 

const getUtcOffset = () => {
    return dayjs().utcOffset()
}


export const getEpoch = () => dayjs(0).hour(0)

export const parseTimeString = (string) => {

    const [hour, minute, second] = split(":")(string)

    return getEpoch().hour(hour).minute(minute).second(second)

}

export const formatTime = (time) => {
    return dayjs(time).format("h:mm A")
}

export const formatDateShort = (date) => {
    return dayjs(date).format("DD/MM/YYYY")
}

export const dateFromDateTime = (dateTime) => {
    dateTime = dayjs(dateTime)
    return getEpoch()
        .year(dateTime?.year())
        .month(dateTime?.month())
        .date(dateTime?.date())
}
export const timeFromDateTime = (dateTime) => {
    dateTime = dayjs(dateTime)
    return getEpoch().hour(dateTime?.hour()).minute(dateTime?.minute())
}

export const utcDate = (date) => {
    const offset = dayjs(date).utcOffset()
    return dayjs(date).subtract(offset, "minutes")
}

export const getDateAsUtc = (date) => {
    return dayjs(date).subtract(getUtcOffset(), "minutes")
}

export const parseBackendDatetime = (dateTimeString) => {
    if (is(String, dateTimeString) && !endsWith("Z", dateTimeString)) {
        return dayjs(dateTimeString + "Z")
    } else {
        return dayjs(dateTimeString)
    }
}

export const combineDateAndTime = (date, time) => {
    return getEpoch()
        .year(date?.year())
        .month(date?.month())
        .date(date?.date())
        .hour(time?.hour())
        .minute(time?.minute())
        .second(time?.second())
        .millisecond(time?.millisecond())
}

export const formatDateTimeForBackend = (dateTime) => {
    const utcDate = getDateAsUtc(dateTime)
    return `${dayjs(utcDate).format('YYYY-MM-DD')}T${dayjs(utcDate).format('HH:mm:ss')}.000Z`
}