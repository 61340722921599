import { TextField } from "@mui/material"

const SearchBarComponent = ({
    placeholder = "Search",
    setSearchValue,
    searchValue
}) => {





    return <TextField
        value={searchValue}
        placeholder={placeholder}
        onChange={(event) => setSearchValue(event?.target?.value)}
        inputProps={{
            sx:{
                paddingY:"10px",
                background:"white",
                width:"50ch"
            }
        }}
    />

}

export default SearchBarComponent