import { useSelector } from "react-redux"
import { NotificationDelegateDialog, NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { Stack } from "@mui/material"
import { blueButtonColor, greenButtonColor } from "../../../styles/style-constants"
import { useEffect, useState } from "react"
import ButtonComponent from "../../../components/button-component"
import { SwapHorizontalCircle, ThumbUp } from "@mui/icons-material"
import { membersRoute } from "../../../utils/page-routes"
import { Link } from "react-router-dom"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { apiPathAcceptDelegation } from "../../../utils/endpoint-paths"
import { isNotUndefined } from "../../../utils/helper-functions/is-null-or-undefined-or-empty"

const getBodyOpener = (name) => `Hey ${name}, `
const getBodyText = () => `You have kindly agreed to volunteer for the following volunteering event:`

const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)
    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))

    useEffect(() => {
        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + getBodyText()}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />
}

const Page = ({ notification, refreshNotifications }) => {
    const name = useSelector(selectUserFirstName)
    const confirmed = notification?.metadata?.confirmed

    const [open, setOpen] = useState(false)

    const { generateEndpoint, doPut } = useApiUtilsContext()

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))

    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))

    const acceptEndpoint = generateEndpoint(apiPathAcceptDelegation(activity?.id));

    const onAccept = async () => {
        try {
            await doPut({ endpoint: acceptEndpoint, queryParams: { notif_id: notification?.id } });
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }

    return <>
        <NotificationDelegateDialog
            open={open}
            setOpen={setOpen}
            name={name}
            notification={notification}
            activity={activity}
            event={event}
            onDelegate={() => refreshNotifications()}
            bodyText="We're sorry to hear that you can no longer attend the following Volunteer Event:"
        />
        <NotificationPageBase
            notification={notification}
            getBodyOpener={() => getBodyOpener(name)}
            getBodyText={() => getBodyText()}
            getBody={() => <NotificationEventDetailsList
                event={event}
                showLocation
                showTime
                activity={activity}
                showActivity
            />}

            getActions={() => <Stack direction={"row"} spacing={2}>
                <ButtonComponent
                    title="Confirm"
                    background={greenButtonColor}
                    icon={<ThumbUp />}
                    onClick={onAccept}
                    disabled={isNotUndefined(confirmed)}
                    disabledOpacity={confirmed ? 1.0 : 0.5}
                />
                <ButtonComponent
                    title="Delegate"
                    onClick={() => setOpen(true)}
                    background={blueButtonColor}
                    icon={<SwapHorizontalCircle />}
                    disabled={isNotUndefined(confirmed)}
                    disabledOpacity={!confirmed ? 1.0 : 0.5}
                />
            </Stack>}

            getFooter={() => <span>
                <span>Can't Find A Replacement? </span>
                <Link
                    to={`${membersRoute}?userTypes=ADMIN`}
                >Contact A Club Admin</Link>
            </span>}
        />
    </>

}


const NotificationVolunteerConfirmation = {
    ListItem,
    Page
}

export default NotificationVolunteerConfirmation