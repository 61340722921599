import { useEffect, useState } from "react"
import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"
import DropdownField from "../../components/fields/dropdown-field"
import { memberTypeDropdownOptions } from "../../utils/constants"
import { Stack } from "@mui/material"
import ButtonComponent from "../../components/button-component"
import { Add, Close } from "@mui/icons-material"
import { greenButtonColor, redButtonColor } from "../../styles/style-constants"
import MultiValueField from "../../components/fields/multi-value-field"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathAddClubMembers, apiPathAddTeamMembersMultiple, apiPathClubTeamsList } from "../../utils/endpoint-paths"
import { isEmpty, reject } from "ramda"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import isAllMembersTeam from "../../utils/helper-functions/is-all-members-team"
import { Link } from "react-router-dom"
import { membersRoute } from "../../utils/page-routes"
import ChipSelectField from "../../components/fields/chip-select-field"
import useDoNavigate from "../../utils/do-navigate"

const AddMembersPage = () => {

    const { generateEndpoint, doPost, doGet, doPut } = useApiUtilsContext()

    const doNavigate = useDoNavigate()

    const clubId = useSelector(selectCurrentClubId)

    const getTeamsEndpoint = generateEndpoint(apiPathClubTeamsList(clubId))

    const addMembersEndpoint = generateEndpoint(apiPathAddClubMembers(clubId))

    const addTeamMembersEndpoint = teamId => generateEndpoint(apiPathAddTeamMembersMultiple(teamId))

    const [memberType, setMemberType] = useState('')
    const [emails, setEmails] = useState([])
    const [addToTeams, setAddToTeams] = useState([])
    const [teamsList, setTeamsList] = useState([])
    const [snackbarLoading, setSnackbarLoading] = useState(false)


    const addMembersDisabled = isEmpty(emails) || isEmpty(memberType)
    const rejectEmpty = reject(isEmpty)

    const onSubmit = async () => {

        setSnackbarLoading(true)
        try {


            const response = await doPost({
                endpoint: addMembersEndpoint, body: {
                    "member_type": memberType,
                    "emails": rejectEmpty(emails)
                }
            })

            const memberIds = response?.data

            for (let index in addToTeams) {
                const team = addToTeams[index]
                try {
                    const endpoint = addTeamMembersEndpoint(team?.id)
                    await doPut({ endpoint, body: memberIds })
                } catch (error) {
                    console.error(error)
                }
            }
        } catch (error) {
            console.error(error)
        }
        setSnackbarLoading(false)
        doNavigate(membersRoute)


    }

    useEffect(() => {

        const getTeams = async () => {
            try {
                const response = await doGet({ endpoint: getTeamsEndpoint })
                setTeamsList(reject(isAllMembersTeam)(response?.data ?? []))
            } catch (error) {
                console.error(error)
            }
        }

        if (clubId) {

            getTeams()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])



    return <BasePageComponent
    backRoute={membersRoute}
    pageTitle={"Add New Members"} 
    snackbarLoading={snackbarLoading}
    >
        <CardContainer padding="20px">
            <Stack spacing={5}>
                <DropdownField
                    label={"What type of member?"}
                    value={memberType}
                    setValue={setMemberType}
                    options={memberTypeDropdownOptions}
                />
                <MultiValueField
                    label="New Member Emails"
                    value={emails}
                    setValue={setEmails}
                />
                <ChipSelectField
                    label={"Add to Team?"}
                    value={addToTeams}
                    setValue={setAddToTeams}
                    options={teamsList}
                    getOptionLabel={(option) => option?.details?.name}
                />

            </Stack>



            <Stack direction="row" paddingTop={"10px"} spacing={2}>
                <ButtonComponent
                    disabled={addMembersDisabled}
                    title={"Add Members"}
                    icon={<Add />}
                    background={greenButtonColor}
                    onClick={onSubmit}
                />
                <ButtonComponent title={"Cancel"} icon={<Close />} background={redButtonColor} component={Link} to={membersRoute} />
            </Stack>
        </CardContainer>


    </BasePageComponent>

}

export default AddMembersPage