import { createTheme } from "@mui/material";
import { primaryColor, backgroundColor } from "./style-constants";
import '@fontsource/montserrat'
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/mulish"

const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        background: {
            default: backgroundColor
        }
    },
    typography: {
        fontFamily: "Montserrat, Mulish"
    }
})


export default theme