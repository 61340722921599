

export const getKeycloakUrl = () => process.env.REACT_APP_KEYCLOAK_URL
export const getBaseUrl = () => process.env.REACT_APP_BACKEND_URL
export const getGoogleApiKey = () => process.env.REACT_APP_GOOGLE_API_KEY
export const getAppName = () => process.env.REACT_APP_NAME
export const getAppVersion = () => process.env.REACT_APP_VERSION
export const getAppEnvironment = () => process.env.REACT_APP_ENVIRONMENT

export const getFirebaseConfig = () => ({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "member-clubs.firebaseapp.com",
    projectId: "member-clubs",
    storageBucket: "member-clubs.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

})
export const getFirebaseVapidKey = () => process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY