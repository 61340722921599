import { Stack, Input, InputAdornment, IconButton, Typography } from "@mui/material";
import FieldLabel from "../field-label";
import { fieldWidth } from "../../../styles/style-constants";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { compose, identity, length, lte, match } from "ramda";

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/

const isPasswordValid = compose(lte(1), length, match(passwordRegex))

const PasswordField = ({ 
    value, 
    setValue, 
    id, 
    placeholder, 
    label, 
    spacing = 2, 
    readOnly = false, 
    setValid = identity, 
    valid=true,
    validatePassword=false,
    mandatory=false,
    errorMessage = "A password must contain at least 8 characters, 1 number and 1 special character!"
}) => {

    const [show, setShow] = useState(false)



    const onChange = (value) => {
        if (validatePassword) {
            setValid(isPasswordValid(value))
        }
        setValue(value)
    }


    const onClick = () => {
        setShow(prev => !prev)
    }

    return (
        <Stack direction="column" spacing={spacing} maxWidth={fieldWidth}>
            <FieldLabel label={label} mandatory/>
            <Input
                id={id}
                type={show ? undefined : 'password'}
                placeholder={placeholder}
                readOnly={readOnly}
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                error={!valid}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={onClick}>
                            {show ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {!valid && <Typography fontSize={"12px"} color={"red"}>
                {errorMessage}
            </Typography>}
        </Stack>
    );
};

export default PasswordField;