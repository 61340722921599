import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty";

const MenuDropDown = ({ items = [], activeIcon = null, decoration }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  // There is probably a better way of doing the delegate modal. I have no idea what

  return (
    <>
      {!isNullOrUndefinedOrEmpty(decoration) && decoration}
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={onClick}
        sx={{ width: "10px", height: "10px", ml: "5px" }}
      >
        <ArrowDropDownIcon
          fontSize="10px"
          style={{ color: "#979797", marginTop: "2.5px" }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClick}
        onClick={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            gap: "5px",
            overflow: "visible",
            width: 250,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            borderRadius: "4px",
            marginLeft: "-50px",
            border: "solid 1px rgba(151, 151, 151, 0.48)",
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {items.map((item, index) => (
          <div>
            <MenuItem
              key={item?.index}
              sx={{ height: "35px" }}
              onClick={() => { item?.onClick(); console.log(item)}}
              disabled={item?.disabled}
            >
              <Stack direction={"row"} gap={"15px"} alignItems={"center"}>
                {item?.icon}
                <Typography
                  color={item?.theme}
                  textAlign={"center"}
                  fontWeight={"medium"}
                  fontSize={"12px"}
                  letterSpacing={"1px"}
                >
                  {item?.title}
                </Typography>
                <Box width={"15px"} />
                {!isNullOrUndefinedOrEmpty(activeIcon) &&
                  item?.isActive &&
                  activeIcon}
              </Stack>
            </MenuItem>
            {index !== items.length - 1 && <Divider />}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default MenuDropDown;
