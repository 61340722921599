import { Input, InputAdornment, Stack } from "@mui/material";
import FieldLabel from "../field-label";
import { fieldWidth } from "../../../styles/style-constants";
import { includes, isEmpty } from "ramda";
import ReadOnlyField from "../readonly-field";

const FloatFormField =  ({
    value, 
    setValue, 
    id, 
    placeholder, 
    label, 
    spacing = 2, 
    width=fieldWidth, 
    readOnly=false, 
    disabled=false,
    decoration,
    mandatory=false,
    step=0.25
}) => {


    const blurValue = () => {
        if (isEmpty(value ?? "")) {
            value = '1.0'
        } else {
            value = Number.parseFloat(value).toFixed(2)
        }

        setValue(value)
    }

    const parseValue = (value) => {
        let newValue = value
        if (isEmpty(value ?? "")) {
            return ""
        } else if (includes(".", value)) {
            newValue = Number.parseFloat(value).toFixed(2)
        }
        if (newValue < 0) {
            newValue = '0.0'
        }
        return newValue
    }

    return (
        <Stack direction="column" spacing={label && spacing} width={width}>
            <FieldLabel label={label} disabled={disabled} mandatory={mandatory}/>
            {readOnly ? <ReadOnlyField value={value} parseValue={(value) => `${value} ${decoration}`}/> :
            <Input
                endAdornment={ decoration && <InputAdornment position="end">{decoration}</InputAdornment>}
                readOnly={readOnly}
                id={id}
                disabled={disabled}
                step={step}
                inputProps={{
                    step:step
                }}
                type="number"
                placeholder={placeholder} 
                value={parseValue(value)}
                onChange={(e) => setValue(parseValue(e.target.value))}
                onBlur={blurValue}
            />}
        </Stack>
    );
};

export default FloatFormField;

