
import { useSelector } from "react-redux"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectEventFromCache, selectUserFirstName, selectUserPersonId } from "../../../redux/selectors"
import { Box, Stack, } from "@mui/material"
import { greenButtonColor, redButtonColor } from "../../../styles/style-constants"

import { useEffect } from "react"
import { CalendarIcon } from "@mui/x-date-pickers"
import ButtonComponent from "../../../components/button-component"
import { Close } from "@mui/icons-material"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { apiPathDeclineAttendee } from "../../../utils/endpoint-paths"




const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)


    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))


    useEffect(() => {
        addEventRequest(notification?.metadata?.event_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => `Hey ${name}, You have an upcoming event coming up. See you there!`}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        image={<CalendarIcon fontSize="large" htmlColor={greenButtonColor} />}
        getBody={() => <NotificationEventDetailsList event={event} />}

    />

}


const Page = ({ notification, refreshNotifications }) => {
    const name = useSelector(selectUserFirstName)
    const personId = useSelector(selectUserPersonId)

    const {generateEndpoint, doPut} = useApiUtilsContext()

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))

    const declineEndpoint = generateEndpoint(apiPathDeclineAttendee(event?.id, personId))

    const onClick = async () => {
        let endpoint = declineEndpoint
        try {
            await doPut({ endpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }


    return <NotificationPageBase
        notification={notification}
        image={<CalendarIcon fontSize="large" htmlColor={greenButtonColor} />}
        getBodyOpener={() => `Hey ${name},`}
        getBodyText={() => "You have an upcoming event coming up. See you there!"}
        getBody={() => <NotificationEventDetailsList
            event={event}
            showLocation
            showTime
        />}

        getActions={() => <>
            <Box paddingBottom={"5px"}>
                No longer can make it?
            </Box>
            <Stack direction={"row"} spacing={2}>
                <ButtonComponent onClick={onClick} icon={<Close />} title={"Cancel Attendance"} background={redButtonColor} />
            </Stack>
        </>
        }
    />

}

const NotificationEventReminder = {
    ListItem,
    Page
}

export default NotificationEventReminder