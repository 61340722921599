import { Container } from "@mui/material"
import { maxPageWidth } from "../../styles/style-constants"
import useIsMobile from "../../utils/helper-functions/use-is-mobile"


const CardContainer = ({
    width = maxPageWidth,
    minWidth,
    maxWidth = "none",
    marginTop = "40px",
    marginLeft = "50px",
    marginRight,
    radius = "4px",
    padding = "10px",
    children
}) => {

    const isMobile = useIsMobile()
    const pageWidth = window.innerWidth

    return <Container
        disableGutters
        sx={{
            width: isMobile ? `${pageWidth}px` : width,
            maxWidth: maxWidth,
            marginTop: isMobile ? "20px" : marginTop,
            marginLeft: isMobile ? "0px" : marginLeft,
            borderRadius: radius,
            marginRight:marginRight,
            minWidth: !isMobile ? minWidth : undefined,
            padding: isMobile ? "10px" : padding,
            background: "white",
            overflow:"hidden"
        }}>
        {children}
    </Container>

}

export default CardContainer