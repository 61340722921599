import { Menu as MenuIcon } from "@mui/icons-material"
import { AppBar, Container, IconButton, Toolbar, Typography } from "@mui/material"
import { useState } from "react"
import DrawerComponent from "../drawer-component"
import ClubAppBarButton from "./club-app-bar-button"
import ClubDrawerComponent from "../club-drawer-component"
import useIsMobile from "../../utils/helper-functions/use-is-mobile"
import { dashboardRoute } from "../../utils/page-routes"
import NotificationAppBarButton from "./notification-app-bar-button"
import useDoNavigate from "../../utils/do-navigate"

const AppBarComponent = () => {
    const [drawerOpen, setDrawOpen] = useState(false)
    const [clubDrawerOpen, setClubDrawerOpen] = useState(false)

    const doNavigate = useDoNavigate()

    const isMobile = useIsMobile()

    return <>
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton onClick={() => setDrawOpen(true)} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={700} onClick={() => {doNavigate(dashboardRoute)}} sx={{"&:hover": {cursor: "pointer"}}}>
                    MemberClubTeams
                </Typography>
                <Container w="100%" />
                <ClubAppBarButton isMobile={isMobile} onClick={() => setClubDrawerOpen(true)}/>
                <NotificationAppBarButton />
            </Toolbar>

        </AppBar>
        <DrawerComponent isOpen={drawerOpen} isMobile={isMobile} onClose={() => setDrawOpen(false)} />
        <ClubDrawerComponent isOpen={clubDrawerOpen} isMobile={isMobile} onClose={() => setClubDrawerOpen(false)} />
    </>
}

export default AppBarComponent