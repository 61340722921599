import ChipSelectField from "../chip-select-field"


const AccessNeedsField = ({
    label = "Do you have any access needs that you want us to accommodate for? (Please select all that applies)",
    readOnly = false,
    value,
    setValue,
    maxWidth = "68ch",
    mandatory=false
}) => {

    const options = [
        {id: "hearingimpairment", name:"Hearing Impairment"},
        {id: "visionimpairment", name:"Vision Impairment"},
        {id: "physicaldisability", name:"Physical Disability"},
        {id: "adhd", name: "ADHD"},
        {id: "autism", name: "Autism"}
    ]


    return <ChipSelectField
        options={options}
        label={label}
        value={value}
        setValue={setValue}
        maxWidth={maxWidth}
        readOnly={readOnly}
        mandatory={mandatory}
    />



}

export default AccessNeedsField