
export const dashboardRoute = "/"
export const eventsRoute = "/events"
export const membersRoute = "/members"
export const teamsRoute = "/teams"
export const notificationsRoute = "/notifications"
export const eventTypesRoute = "/adminevents"
export const activityTypesRoute = "/adminactivities"
export const qualificationsRoute = "/adminqualifications"
export const opponentsRoute = "/adminopponents"
export const editOpponentRoute = "/editopponent"
export const clubDetailsRoute = "/clubdetails"

export const addMembersRoute = "/membersadd"
export const editMembersRoute = "/membersedit"
export const editTeamRoute = "/editteam"
export const editEventRoute = "/editevent"

export const onboardingRoute = "/onboarding"
export const aboutRoute = "/about"
export const leaderboardRoute = "/leaderboard"

export const qualRoute = "/quals"