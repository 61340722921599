import { Stack, TextField, Autocomplete } from "@mui/material";
import FieldLabel from "../field-label";
import { fieldWidth } from "../../../styles/style-constants";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getGoogleApiKey } from "../../../utils/envs";
import { useEffect, useState } from "react";
import { equals, isEmpty, map } from "ramda";
import ReadOnlyField from "../readonly-field";

const GoogleMapsField = ({ value = "", setValue, id, placeholder, label, spacing = 2, readOnly = false, mandatory=false }) => {


    const [inputValue, setInputValue] = useState("")

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({ apiKey: getGoogleApiKey() })


    const mappedPlacePredictions = map((place) => {
        return {
            place_id: place?.place_id,
            description: place?.description
        }
    }, placePredictions)


    useEffect(() => {
        getPlacePredictions({ input: inputValue })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    const getOptionLabel = (option) => {
        return option?.description ?? ""
    }

    const isOptionEqualToValue = (a, b) => equals(a?.placeId, b?.placeId)


    return (
        <Stack direction="column" spacing={spacing} maxWidth={fieldWidth}>
            <FieldLabel label={label} mandatory={mandatory} readOnly={readOnly}/>
            {readOnly ? <ReadOnlyField value={value} parseValue={(option) => option?.description ?? ""}/> :
            <Autocomplete
                id={id}
                readOnly={readOnly}
                value={value}
                sx={{
                    maxWidth:fieldWidth
                }}
                filterOptions={(options, state) => options}
                onChange={(event, newValue) => setValue(newValue)}
                isOptionEqualToValue={isOptionEqualToValue}
                options={isPlacePredictionsLoading ? [] : mappedPlacePredictions}
                getOptionLabel={getOptionLabel}
                inputValue={inputValue}
                noOptionsText={isPlacePredictionsLoading ? "Loading..." : isEmpty(inputValue) ? "Start typing to search..." : "No options"}
                onInputChange={(event, newValue) => setInputValue(newValue)}
                renderInput={(params) =>
                    <TextField
                        multiline
                        variant="standard"
                        {...params}
                    />
                }
            />}

        </Stack>
    );
};

export default GoogleMapsField;