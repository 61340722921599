import { Stack } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import TableComponent, { columnTypePhone, columnTypeString } from "../../components/table-component"
import ButtonComponent from "../../components/button-component"
import { Add } from "@mui/icons-material"
import { editOpponentRoute } from "../../utils/page-routes"
import { newFormEntityId } from "../../utils/constants"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathGetOpponentList } from "../../utils/endpoint-paths"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import { useEffect, useState } from "react"
import { map } from "ramda"
import useDoNavigate from "../../utils/do-navigate"

const OpponentsPage = () => {

    const doNavigate = useDoNavigate()

    const clubId = useSelector(selectCurrentClubId)

    const [items, setItems] = useState([])

    const {generateEndpoint, doGet} = useApiUtilsContext()


    const onCreateOpponent = () => {
        doNavigate(editOpponentRoute, {target: newFormEntityId})
    }

    const onSelectOpponent = (opponent) => {
        doNavigate(editOpponentRoute, {target: opponent.id})
    }

    const mapOpponent = (opponent) => {
        return {
            id: opponent?.id,
            name: opponent?.details?.name,
            description: opponent?.details?.description,
            clubContact: opponent?.mobile,
            members: opponent?.mainContact?.name
        }
    }

    const getOpponentsEndpoint = generateEndpoint(apiPathGetOpponentList(clubId))

    useEffect(() => {

        const getDetails = async () => {
            try {
                const response = await doGet({endpoint: getOpponentsEndpoint})
                setItems(map(mapOpponent)(response?.data ?? []))
            } catch (error) {
                console.error(error)
            }
        }

        if (clubId) {
            getDetails()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <BasePageComponent pageTitle={"Opponents"} width="100%">
        <Stack direction={"column"} sx={{
            marginX: "50px"
        }}>
            <Stack direction="row" marginY="40px" justifyContent={"flex-end"}>
                <ButtonComponent
                    title="Add New Opponent"
                    icon={<Add />}
                    onClick={onCreateOpponent}
                />
            </Stack>
        <TableComponent
            items={items}
            onRowClick={onSelectOpponent}
            columns={[
                { key: "name", title: "Opponent", type: columnTypeString },
                { key: "description", title: "Description", type: columnTypeString },
                { key: "clubContact", title: "Club Contact", type: columnTypePhone },
                { key: "members", title: "Members", type: columnTypeString }
            ]}
        />
    </Stack>

    </BasePageComponent >

}

export default OpponentsPage