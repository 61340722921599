import { ButtonBase, Typography } from "@mui/material"
import { blueButtonColor } from "../../styles/style-constants"

const ButtonComponent = ({ 
    disabled = false, 
    title, 
    icon, 
    onClick, 
    component, 
    to, 
    background=blueButtonColor,
    disabledBackground,
    disabledColor,
    color="white",
    fontSize,
    disabledOpacity = 0.5
}) => {

    return <ButtonBase
        disabled={disabled}
        onClick={onClick}
        component={component}
        to={to}
        sx={{
            background:disabled ? (disabledBackground ?? background) : background,
            borderRadius:"4px",
            color:disabled ? (disabledColor ?? color) : color,
            padding:"5px",
            paddingX:"10px",
            maxHeight:"4.5vh",
            opacity: disabled ? disabledOpacity : 1.0,
            cursor: disabled ? 'not-allowed' : 'pointer'
        }}    
    >
        {icon}
        <Typography marginLeft={!!icon ? "5px" : undefined} fontFamily={"mulish"} fontSize={fontSize}>
            {title}
        </Typography>
    </ButtonBase>

}

export default ButtonComponent