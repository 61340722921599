import { CloudUpload, } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { useRef } from "react"
import { length } from "ramda"


const DocumentField = ({ setValue, value }) => {


    const inputRef = useRef()

    const { fileName } = value ?? {}


    const onClick = () => {
        inputRef.current.click()
    }

    const onUpload = (fileList) => {
        const fileReader = new FileReader()


        if (length(fileList) > 0) {
            const file = fileList[0]
            let data = new FormData()

            data.append('file', file)
            setValue({ formData: data, minioId: undefined, fileName: file?.name })
            fileReader.readAsDataURL(file)
        }
    }


    return <Stack><Button
        onClick={onClick}
        sx={{
            width:"15ch",
            background: "#E0E0E0"
        }}>
        <input
            type="file"
            id="file"
            accept="image/*, .pdf"
            max={1}
            onChange={(e) => {
                onUpload(e.target.files)
            }}
            ref={inputRef}
            style={{ display: "none" }}
        />
        <Stack direction="row" spacing={1}><p>UPLOAD</p><CloudUpload /></Stack>

    </Button>
        <Typography textOverflow={"ellipsis"} maxWidth={"20ch"}>{fileName}</Typography>
    </Stack>


}

export default DocumentField