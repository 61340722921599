import { useState, useEffect } from "react"
import { Stack } from "@mui/material"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"
import ObjectListComponent from "../../components/object-list-component"
import FormHeader from "../../components/form-header-component"
import ButtonStack from "../../components/buttons/save-cancel-add-stack"
import BooleanField from "../../components/fields/boolean-field"
import { useSelector } from "react-redux"
import { selectCurrentClubId } from "../../redux/selectors"
import { apiPathCreateQualificationType, apiPathGetQualificationTypeList, apiPathPutQualificationType } from "../../utils/endpoint-paths"
import { newFormEntityId } from "../../utils/constants"
import { equals, head, reject, update } from "ramda"
import { isNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import useDebounce from "../../utils/use-debounce"
import { useSearchParams } from "react-router-dom"

const QualificationsPage = () => {

    const { generateEndpoint, doGet, doPut, doPost, uploadPfp } = useApiUtilsContext()


    const [searchParams, setSearchParams] = useSearchParams()
    const currentQualId = searchParams.get("target") ?? undefined
    const setCurrentQualId = (id) => setSearchParams({target:id})

    const [qualInput, setQualInput] = useState("")
    const [qualSearch, setQualSearch] = useState()

    const currentClubId = useSelector(selectCurrentClubId);

    const getQualsListEndpoint = generateEndpoint(apiPathGetQualificationTypeList(currentClubId))
    const updateQualEndpoint = generateEndpoint(apiPathPutQualificationType(currentClubId, currentQualId))
    const createQualEndpoint = generateEndpoint(apiPathCreateQualificationType(currentClubId))

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageId, setImageId] = useState();
    const [imageFormData, setImageFormData] = useState()
    const [cert, setCert] = useState(null);


    const isCreatingNew = currentQualId === newFormEntityId


    const [quals, setQuals] = useState([]);

    const [loading, setLoading] = useState(false)

    const currentQual = quals.find((qual) => `${qual.id}` === `${currentQualId}`);

    const currentQualIndex = quals.findIndex((qual) => `${qual.id}` === `${currentQualId}`);


    const disabled = currentQualId === undefined


    const onSubmit = async () => {
        setLoading(true)
        try {

            let pictureId = imageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }

            const body = {
                details: {
                    name: title,
                    description: description,
                    pictureId
                },
                needsCertification: cert,
            };
            if (isCreatingNew) {


                const response = await doPost({
                    endpoint: createQualEndpoint,
                    body: body
                });

                const newQualResponse = await doGet({
                    endpoint: response?.headers?.location
                });

                //Set new stuff
                updateCurrentQual(newQualResponse.data);
            } else {


                await doPut({
                    endpoint: updateQualEndpoint,
                    body: body
                });

                //Set new stuff
                updateCurrentQual({ id: currentQualId, ...body });
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    };

    useDebounce(() => {
        setQualSearch(qualInput)
    }, 500, [qualInput])

    useEffect(() => {
        const getQualsList = async () => {
            const queryParams = {
                name: qualSearch?.length > 0 ? qualSearch : undefined
            }
            try {
                const response = await doGet({ endpoint: getQualsListEndpoint, queryParams })
                setQuals(response?.data)
            } catch (error) {
                console.error(error)
            }
        }

        if (currentClubId) {
            getQualsList()
        }
    }, [currentClubId, doGet, getQualsListEndpoint, qualSearch]);

    useEffect(() => {
        if (currentQualId) {

            setTitle(currentQual?.details?.name ?? "");
            setDescription(currentQual?.details?.description ?? "");
            setCert(currentQual?.needsCertification ?? "");
            setImageId(currentQual?.details?.pictureId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, currentQualId, quals]);

    const addNewQual = () => {
        setCurrentQualId(newFormEntityId);
        setQuals((prev) => [
            { id: newFormEntityId },
            ...prev
        ])
    };

    const onCancel = () => {
        if (isCreatingNew) {
            const newQuals = reject((qual) => equals(qual?.id)(newFormEntityId))(quals)
            setQuals(newQuals)
            setCurrentQualId(head(newQuals)?.id)
        } else {
            setTitle(currentQual?.details?.name ?? "");
            setDescription(currentQual?.details?.description ?? "");
            setCert(currentQual?.needsCertification ?? "");
            setImageId(currentQual?.details?.pictureId ?? null)
        }
    };

    const updateCurrentQual = (newQual) => {
        //update the current details to new details
        setQuals((prev) => update(currentQualIndex, newQual, prev));
        setCurrentQualId(newQual?.id);
    };

    return <BasePageComponent
        pageTitle={"Qualifications"}
        marginRight="60px"
        snackbarLoading={loading}
        inlineContent={
            <ButtonStack
                addLabel="Add New Qualification"
                addAction={addNewQual}
                saveAction={onSubmit}
                cancelAction={onCancel}
                disableSave={
                    disabled || 
                    isNullOrUndefinedOrEmpty(title) || 
                    isNullOrUndefinedOrEmpty(description) ||
                    isNullOrUndefinedOrEmpty(cert)
                }
                disableCancel={disabled}
                disableAdd={currentQualId === newFormEntityId}
            />
        }
    >
        <Stack direction="row" spacing={5} padding={5}>
            <ObjectListComponent
                title={"Qualifications"}
                searchPlaceholder={"Search for Qualification Type..."}
                items={quals}
                getItemName={(qual) => qual?.details?.name ?? "New Qualification"}
                onSelectItem={(qual) => setCurrentQualId(qual?.id)}
                selectedKey={currentQualId}
                searchValue={qualInput}
                setSearchValue={setQualInput}
            />

            <CardContainer padding={"20px 30px 50px 30px"}>
                <Stack direction="column" spacing={6}>
                    <FormHeader
                        label={"Qualification"}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                        minioId={imageId}
                        setMinioId={setImageId}
                        setFormData={setImageFormData}
                        disabled={disabled}
                        mandatory
                    />
                    <BooleanField
                        mandatory
                        label="Certification Required?"
                        value={cert}
                        setValue={setCert}
                        disabled={disabled}
                    />
                </Stack>
            </CardContainer>

        </Stack>


    </BasePageComponent>


}

export default QualificationsPage