import { Box, ButtonBase, Grid, Stack, Typography } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import ProgressCircleComponent from "../../components/progress-circle-component"
import { blueButtonColor, greenButtonColor, redButtonColor, yellowColor } from "../../styles/style-constants"
import { editEventRoute, eventsRoute, leaderboardRoute } from "../../utils/page-routes"
import TableComponent from "../../components/table-component"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { useSelector } from "react-redux"
import { selectCurrentClubId, selectUserMemberId, selectUserPersonId } from "../../redux/selectors"
import { useEffect, useState } from "react"
import { apiPathGetDashboard, apiPathGetUpcomingEvents, apiPathLeaderboard } from "../../utils/endpoint-paths"
import { append, compose, equals, find, map, not } from "ramda"
import { formatDateShort, formatTime, parseBackendDatetime } from "../../utils/helper-functions/datetime-functions"
import getHomeAwayName from "../../utils/helper-functions/get-home-away-name"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import useDoNavigate from "../../utils/do-navigate"
import { useIsAdmin } from "../../utils/user-hooks"



const StatusCircle = ({ color, count, label }) => {
    return <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
        <Stack
            sx={{
                borderRadius: "100%",
                borderColor: color,
                border: "solid 3px",
                height: "100px",
                width: "100px",
                textAlign: "center",
                justifyContent: "center",
                fontWeight: 700,
                fontSize: "30px",
                color: color
            }}
        > {count}</Stack>
        <Typography color={color} textTransform={"uppercase"} fontWeight={700} width={"100px"} textAlign={"center"}>
            {label}
        </Typography>
    </Stack>

}


const DashboardPage = () => {

    const doNavigate = useDoNavigate()

    const {generateEndpoint, doGet} = useApiUtilsContext()
    const isAdmin = useIsAdmin()
    const clubId = useSelector(selectCurrentClubId)
    const memberId = useSelector(selectUserMemberId)

    const [activityStats, setActivityStats] = useState({})
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [leaderboard, setLeaderboard] = useState([])
    const [userRanking, setUserRanking] = useState()

    const statsEndpoint = generateEndpoint(apiPathGetDashboard(clubId)); 
    //generateEndpoint(isAdmin ? apiPathGetAdminDashboard(clubId) : apiPathGetMemberDashboard(personId))
    const upcomingEventsEndpoint = generateEndpoint(apiPathGetUpcomingEvents(memberId))
    const leaderboardEndpoint = generateEndpoint(apiPathLeaderboard(clubId))


    const mapUpcomingEvents = (data) => {
        const startDate = parseBackendDatetime(data?.startTime)
        return {
            id: data.eventId,
            eventName: data?.eventName,
            role: data?.role ?? "Team Member",
            type: data?.type,
            homeOrAway: getHomeAwayName(data?.homeAway),
            time: formatTime(startDate),
            date: formatDateShort(startDate)
        }
    }


    const userInTopFive = compose(isNotNullOrUndefinedOrEmpty,
        find((item) => equals(item?.rank, userRanking?.rank))
        )(leaderboard)

    
    const leaderboardRankList = not(userInTopFive) ? append(userRanking, leaderboard) : leaderboard

    const mappedLeaderboard = map((item) => {

        let name = item?.name
        if (item?.rank === userRanking?.rank) {
            name = `${name} (You)`
        }

        return {
            rank: item?.rank,
            name: name,
            hours_comp: item?.hours_comp,
            hours_remaining: item?.hours_remaining,
            hours_committed: item?.hours_committed,
            percentage: (100*(item?.percent_comp ?? 0)).toFixed(1) + "%",
        }
    })(leaderboardRankList)


    useEffect(() => {

        const fetchActivities = async () => {
            try {
                const response = await doGet({endpoint: statsEndpoint})
                setActivityStats(response?.data)
            } catch (error) {
                console.error(error)
            }

            
        }

        const fetchUpcomingEvents = async () => {
            try {
                const response = await doGet({endpoint: upcomingEventsEndpoint})
                setUpcomingEvents(map(mapUpcomingEvents)(response?.data ?? []))
            } catch (error) {
                console.error(error)
            }
        }


        const fetchActiveMembers = async () => {
            try {
                const response = await doGet({endpoint: leaderboardEndpoint, queryParams:{limit:5}})
                setLeaderboard(response?.data?.leaderboard ?? [])
                setUserRanking(response?.data?.user)
            } catch (error) {
                console.error(error)
            }
        }

        if (clubId) {
            fetchActivities()
            fetchUpcomingEvents()
            fetchActiveMembers()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])

    const round = (num) => (places) => {
        const factor = Math.pow(10, places);
        return Math.round((num ?? 0) * factor) / factor;
    }

    const numPlaces = 1;
    const roundRemaining = round(activityStats?.remaining)(numPlaces);
    const roundCommitted = round(activityStats?.committed)(numPlaces);
    const roundCompleted = round(activityStats?.completed)(numPlaces);
    const roundTotal = round(activityStats?.total)(numPlaces);


    return <BasePageComponent

        pageTitle={"Your Dashboard"}
        marginRight="40px"
        width={"100%"}
    >

        <Grid container direction={"row"} justifyContent={"flex-start"} spacing={3} padding={"40px"}>
            <Grid item xs={12} lg={8} minWidth={"500px"} maxWidth={"100%"}>
                <Box sx={{ background: "white", padding: "10px", height: "100%" }}>
                    <Typography fontSize={"20px"}>
                        {isAdmin ? "Club Volunteering Status" : "Volunteering Status"}
                    </Typography>
                    <Stack direction="row" alignItems={"center"} height={"100%"} justifyContent={"space-between"} paddingBottom={"20px"}>
                        <StatusCircle color={redButtonColor} count={roundRemaining} label={"Remaining Hours"} />
                        <StatusCircle color={yellowColor} count={roundCommitted} label={"Committed Hours"} />
                        <StatusCircle color={greenButtonColor} count={roundCompleted} label={"Completed Hours"} />
                        <StatusCircle color={"#323E59"} count={roundTotal} label={"Total Required Hours"} />
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={12} lg={4} minWidth={"400px"} maxWidth={"100%"}>
                <Box sx={{ background: "white", padding: "10px" }}>
                    <Typography fontSize={"20px"}>
                        {isAdmin ? "Club Volunteering Progress" : "Volunteering Progress"}
                    </Typography>
                    <Stack alignItems={"center"}>
                        <ProgressCircleComponent width="380" hoursCompleted={roundCompleted} hoursTotal={roundTotal} />
                    </Stack>

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ background: "white", padding: "10px" }}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography fontSize={"20px"}>
                            Your Upcoming Events
                        </Typography>
                        <ButtonBase sx={{ color: blueButtonColor }} onClick={() => {
                            doNavigate(eventsRoute)
                        }}>
                            See all events
                        </ButtonBase>
                    </Stack>

                    <TableComponent
                        items={upcomingEvents}
                        onRowClick={(item) => {
                            doNavigate(editEventRoute, {target: item?.id})
                        }}
                        columns={[
                            { key: "date", title: "Date" },
                            { key: "time", title: "Time" },
                            { key: "eventName", title: "Event Name" },
                            { key: "type", title: "Type" },
                            { key: "homeOrAway", title: "H/A" },
                            // { key: "pitch", title: "Pitch" },
                            { key: "role", title: "Role"}
                        ]
                        }
                    />

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ background: "white", padding: "10px" }}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography fontSize={"20px"}>
                            Most Active Volunteers
                        </Typography>
                        <ButtonBase sx={{ color: blueButtonColor }} onClick={() => {
                            doNavigate(leaderboardRoute)
                        }}>
                            See Full Ranking
                        </ButtonBase>
                    </Stack>
                    <TableComponent
                        items={mappedLeaderboard}
                        columns={[
                            { key: "rank", title: "Rank" },
                            { key: "name", title: "Name" },
                            { key: "hours_comp", title: "Hours Completed" },
                            { key: "hours_committed", title: "Hours Committed" },
                            { key: "hours_remaining", title: "Hours Remaining" },
                            { key: "percentage", title: "% Completed" },
                        ]
                        }
                    />
                </Box>
            </Grid>

        </Grid>

    </BasePageComponent>


}


export default DashboardPage