import { useEffect, useState } from "react"
import BasePageComponent from "../../components/base-page-component"
import ButtonComponent from "../../components/button-component"
import InitialOnboardingPageOne from "./initial-page-one"
import dayjs from "dayjs"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentClubId, selectUserMemberId, selectUserUuid } from "../../redux/selectors"
import { apiPathAddBulkQual, apiPathGetMemberClubs, apiPathGetUser, apiPathOnboard, apiPathPutUser, apiPathResetPassword } from "../../utils/endpoint-paths"
import { all, filter, any, equals, find, isEmpty, keys, length, map } from "ramda"
import { setUserClubs, setUserDetails } from "../../redux/app-slice"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import { dashboardRoute } from "../../utils/page-routes"
import useIsMobile from "../../utils/helper-functions/use-is-mobile"
import InitialOnboardingPageTwo from "./initial-page-two"
import { Box, LinearProgress, Stack, Typography, linearProgressClasses } from "@mui/material"
import { blueButtonColor, greenButtonColor } from "../../styles/style-constants"
import OnboardingUploadPage from "./upload-page"
import OnboardingFinalPage from "./final-page"
import OnboardingPageOne from "./page-one"
import useDoNavigate from "../../utils/do-navigate"
import {  apiPathGetQualificationTypeList } from "../../utils/endpoint-paths"
import MobileAppStack from "../../components/buttons/mobile-app-stack/MobileAppStack"



const OnboardingPage = () => {

    const dispatch = useDispatch()
    const dispatchUserDetails = userDetails => dispatch(setUserDetails(userDetails))

    const doNavigate = useDoNavigate()
    const isMobile = useIsMobile()

    const clubId = useSelector(selectCurrentClubId)
    const userUuid = useSelector(selectUserUuid)
    const memberId = useSelector(selectUserMemberId)

    const dispatchUserClubs = (clubs) => dispatch(setUserClubs(clubs))

    const { generateEndpoint, doPut, uploadPfp, doGet, uploadQualification, doPost } = useApiUtilsContext()

    const [page, setPage] = useState(0)

    const [loading, setLoading] = useState(false)


    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [validPassword, setValidPassword] = useState(false)

    const [qualList, setQualList] = useState([])
    const [selectedQualIds, setSelectedQualsIds] = useState([])

    const passwordsMatch = equals(password, confirmPassword)


    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [preferredName, setPreferredName] = useState()
    const [dateOfBirth, setDateOfBirth] = useState({})
    const [gender, setGender] = useState()
    const [indigenous, setIndigenous] = useState()
    const [contactNumber, setContactNumber] = useState()
    const [address, setAddress] = useState()
    const [emergencyContactName, setEmergencyContactName] = useState()
    const [emergencyContactNumber, setEmergencyContactNumber] = useState()
    const [accessNeeds, setAccessNeeds] = useState([])
    const [needsDetails, setNeedsDetails] = useState()
    const [needsPrivate, setNeedsPrivate] = useState(false)
    const [imageId, setImageId] = useState()
    const [imageFormData, setImageFormData] = useState()
    const [initiallyOnboarded, setInitiallyOnboarded] = useState(false)

    const [uploadedQuals, setUploadedQuals] = useState([])
    const [clubImageId, setClubImageId] = useState()

    const qualEndpoint = generateEndpoint(apiPathGetQualificationTypeList(clubId))


    const updateUserEndpoint = generateEndpoint(apiPathPutUser(userUuid))
    const resetPasswordEndpoint = generateEndpoint(apiPathResetPassword(userUuid))
    const uploadQualEndpoint = generateEndpoint(apiPathAddBulkQual(memberId))
    const onboardEndpoint = generateEndpoint(apiPathOnboard(memberId))

    const pageOneMandatoryValues = [
        firstName, lastName, dateOfBirth, contactNumber, address, emergencyContactName, emergencyContactNumber
    ]

    const getUserDetails = generateEndpoint(apiPathGetUser(userUuid))

    const alreadyInitiallyOnboarded = (data) => {

        const strings = [
            data?.firstName,
            data?.lastName,
            data?.mobile,
            data?.emergencyContact?.name,
            data?.emergencyContact?.mobile,
            data?.dateOfBirth
        ]
        const objects = [
            data?.location,
        ]


        const anyStrings = any((value) => isEmpty(value ?? ""))(strings)

        const anyObjects = any((value) => isEmpty(keys(value ?? {})))(objects)
        
        return !anyStrings && !anyObjects
    }
    
    useEffect(() => {

        const getDetails = async () => {
            const response = await doGet({ endpoint: getUserDetails })

            const data = response?.data ?? {}

            setFirstName(data?.firstName)
            setLastName(data?.lastName)
            setPreferredName(data?.preferredName)
            if (data?.dateOfBirth) {
                setDateOfBirth(dayjs(data?.dateOfBirth))
            }
            setGender(data?.gender)
            setContactNumber(data?.mobile)
            setIndigenous(data?.indigenous)
            setEmergencyContactName(data?.emergencyContact?.name)
            setEmergencyContactNumber(data?.emergencyContact?.mobile)
            setAccessNeeds(data?.accessNeeds?.needs)
            setNeedsDetails(data?.accessNeeds?.additionalDetails)
            setNeedsPrivate(data?.accessNeeds?.isPrivate)
            setAddress(data?.location)
            setImageId(data?.pictureId)

            setInitiallyOnboarded(alreadyInitiallyOnboarded(data))
        }

        if (userUuid) {
            getDetails()
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId, userUuid])

    const onSubmitUserDetails = async () => {
        setLoading(true)
        const passwordBody = {
            password: password
        }

        await doPut({ endpoint: resetPasswordEndpoint, body: passwordBody })

        let pictureId = imageId
        if (imageFormData) {
            pictureId = await uploadPfp(imageFormData)
        }

        const body = {
            firstName,
            lastName,
            emergencyContact: {
                name: emergencyContactName,
                mobile: emergencyContactNumber
            },
            gender,
            mobile: contactNumber,
            dateOfBirth: dateOfBirth && dayjs(dateOfBirth).format("YYYY-MM-DD"), //BACKENDDATEFORMAT
            preferredName,
            indigenous,
            location: address,
            pictureId: pictureId,
            accessNeeds: {
                needs: map(need => need?.id ?? need)(accessNeeds),
                additionalDetails: needsDetails,
                isPrivate: needsPrivate
            }
        }

        try {

            await doPut({ endpoint: updateUserEndpoint, body })
            dispatchUserDetails(body)
        } catch (error) {
            console.error(error)
        }


        setLoading(false)
    }

    const onSubmitQualifications = async () => {
        setLoading(true)

        try {
            let certsToUpload = []
            let qualsToPost = []


            for (let index in selectedQualIds) {
                const qual = find((qual) => equals(qual?.id)(selectedQualIds[index]))(qualList)
                let qualObj = qual
                if (qualObj?.needsCertification) {
                    const uploadQual = find((uploadQual) => equals(uploadQual?.id)(qual?.id))(uploadedQuals)
                    qualObj = { ...qual, ...uploadQual }
                    certsToUpload = [...certsToUpload, qualObj]
                }
                qualsToPost = [...qualsToPost, qualObj]
            }

            let result = {}
            for (let index in certsToUpload) {
                let formData = certsToUpload[index]?.document?.formData
                result[certsToUpload[index]?.id] = await uploadQualification(formData)
            }

            const body = map((qual) => {
                let other = {}
                if (qual?.needsCertification) {
                    other.uploadId = result[qual?.id]
                    other.expiry = dayjs(qual?.expiry).format("YYYY-MM-DD")
                }
                return {
                    typeId: qual?.id,
                    ...other
                }
            })(qualsToPost)

            await doPost({ endpoint: uploadQualEndpoint, body })
        } catch (error) {
            console.error(error)
        }


        setLoading(false)
    }

    const doOnboard = async () => {
        await doPut({ endpoint: onboardEndpoint })
    }

    useEffect(() => {
        const getData = async () => {
            if (clubId) {
                const response = await doGet({endpoint:qualEndpoint})
                const quals = response?.data
                setQualList(response?.data)
                // skip qual select if we aren't capturing user data and club has no quals
                if (initiallyOnboarded && quals.length === 0) {
                    setPage(2);
                }

                //TODO  get current quals
            }
        }
        
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiallyOnboarded]);

    const canSubmit = () => {
        //TODO: have pages set this internally would be more reliable
        const isUploadCertPage = (page === 2 && !initiallyOnboarded) || (page === 1 && initiallyOnboarded)
        if (page === 0 && !initiallyOnboarded) {
            return validPassword && passwordsMatch && all(isNotNullOrUndefinedOrEmpty)(pageOneMandatoryValues)
        } else if (isUploadCertPage) {
            return all(uploadedQual => {
                    const hasDoc = isNotNullOrUndefinedOrEmpty(uploadedQual?.document)
                    const hasExpiry = isNotNullOrUndefinedOrEmpty(uploadedQual?.expiry)
                    return hasDoc && hasExpiry
                }
            )(uploadedQuals)
        }

        return true
    }

    const disableNextButton = !canSubmit()

    const pages = initiallyOnboarded ? [
        <OnboardingPageOne
            memberId={memberId}
            clubId={clubId}
            isMobile={isMobile}
            qualList={qualList}
            setQualList={setQualList}
            selectedQuals={selectedQualIds}
            setSelectedQuals={setSelectedQualsIds}
            clubImageId={clubImageId}
        />,
        <OnboardingUploadPage
            qualList={qualList}
            selectedQuals={selectedQualIds}
            uploadedQuals={uploadedQuals}
            setUploadedQuals={setUploadedQuals}
        />,
        <OnboardingFinalPage
            clubImageId={clubImageId}
            initial={false}
        />
    ] : [
        <InitialOnboardingPageOne
            isMobile={isMobile}
            userUuid={userUuid}
            clubId={clubId}
            firstName={firstName}
            lastName={lastName}
            preferredName={preferredName}
            dateOfBirth={dateOfBirth}
            gender={gender}
            indigenous={indigenous}
            contactNumber={contactNumber}
            address={address}
            emergencyContactName={emergencyContactName}
            emergencyContactNumber={emergencyContactNumber}
            accessNeeds={accessNeeds}
            needsDetails={needsDetails}
            needsPrivate={needsPrivate}
            password={password}
            confirmPassword={confirmPassword}
            validPassword={validPassword}
            imageId={imageId}
            passwordsMatch={passwordsMatch}
            imageFormData={imageFormData}
            clubImageId={clubImageId}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPreferredName={setPreferredName}
            setDateOfBirth={setDateOfBirth}
            setGender={setGender}
            setIndigenous={setIndigenous}
            setContactNumber={setContactNumber}
            setAddress={setAddress}
            setEmergencyContactName={setEmergencyContactName}
            setEmergencyContactNumber={setEmergencyContactNumber}
            setAccessNeeds={setAccessNeeds}
            setNeedsDetails={setNeedsDetails}
            setNeedsPrivate={setNeedsPrivate}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            setValidPassword={setValidPassword}
            setImageId={setImageId}
            setImageFormData={setImageFormData}
            setClubImageId={setClubImageId}
        />,
        <InitialOnboardingPageTwo
            memberId={memberId}
            clubId={clubId}
            isMobile={isMobile}
            qualList={qualList}
            setQualList={setQualList}
            selectedQuals={selectedQualIds}
            setSelectedQuals={setSelectedQualsIds}
        />,
        <OnboardingUploadPage
            qualList={qualList}
            selectedQuals={selectedQualIds}
            uploadedQuals={uploadedQuals}
            setUploadedQuals={setUploadedQuals}
        />,
        <OnboardingFinalPage
            clubImageId={clubImageId}
            initial={true}
        />
    ]
    
    const qualsNeedingCert = selectedQualIds?.length === 0 ? [] : filter(qual => qual.needsCertification && any(q => qual.id === q)(selectedQualIds))(qualList)

    const onClickInitiallyOnboarded = async () => {
        
        switch(page) {
            case 0: // qual select page
                const needsCerts = qualsNeedingCert?.length !== 0
                if (needsCerts) {
                    setPage(1)
                } else {
                    // if we selected quals that don't have certs, still need to upload
                    if (selectedQualIds?.length !== 0) {
                        await onSubmitQualifications()
                    }

                    // we're done
                    await doOnboard();
                    setPage(2)
                }
                break;
            case 1: // cert upload page
                await onSubmitQualifications()
                // we're done
                await doOnboard();
                setPage(2)
                break;
            case 2: // go to dashboard page
                // profit
                break;
            default:
                // keep this here unless we have a better idea. This is just to tip us off if
                // anything goes wrong
                console.error("Unhandled page condition on page:", page)
                break;
        }
    }

    const clubHasQuals = qualList.length !== 0;
    const onClickFirstOnboarding = async() => {

        switch(page) {
            case 0:
                // Ensure user details are submitted (I feel like this could be put into the sub component for this page ..)
                await onSubmitUserDetails()
                if (!clubHasQuals) {
                    // we're done
                    await doOnboard();
                    setPage(3)
                } else {
                    setPage(1);
                }
                return;
            case 1:
                if (qualsNeedingCert.length === 0) {
                    // we're done
                    await doOnboard();
                    setPage(3)

                    // If there are still selected qualifications without a cert, need to submit here
                    if (selectedQualIds.length !== 0) {
                        await onSubmitQualifications()
                    }
                } else {
                    setPage(2);
                    return;
                }
                return;
            case 2:
                setPage(3)
                await doOnboard();
                return;
            default:
                // keep this here unless we have a better idea. This is just to tip us off if
                // anything goes wrong
                console.error("Unhandled page condition on page:", page)
        }

    }


    const onClick = async () => {
        if (page + 1 >= length(pages)) {
            // fault tolerance
            await doOnboard();
            const getUserClubsEndpoint = generateEndpoint(apiPathGetMemberClubs(userUuid))
            const clubsResponse = await doGet({ endpoint: getUserClubsEndpoint })
            dispatchUserClubs(clubsResponse?.data)
            doNavigate(dashboardRoute)
            return
        }

        if (initiallyOnboarded) {
            await onClickInitiallyOnboarded();
        } else {
            await onClickFirstOnboarding()
        }
        return
    }

    const onClickBack = () => {
        setPage((prev) => {
            if (prev > 0) {
                return prev - 1
            } else {
                return 0
            }
        })
    }


    return (
      <BasePageComponent snackbarLoading={loading} onboardRedirect={false}>
        <Stack width={"min-content"} direction="column">
          <Box marginLeft={isMobile ? "0px" : "50px"} marginTop="10px">
            <Typography
              fontSize={"14px"}
              color={blueButtonColor}
              width={"100%"}
              textAlign={"right"}
            >
              Page {page + 1} of {length(pages)}
            </Typography>
            <LinearProgress
              sx={{
                background: "#B3DAFA",
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: blueButtonColor,
                },
              }}
              value={Math.ceil(((page + 1) / length(pages)) * 100)}
              variant="determinate"
            />
          </Box>

          {pages[page]}

          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"flex-end"}
            paddingY={"10px"}
            spacing={3}
          >
            {page > 0 && page + 1 < length(pages) && (
              <ButtonComponent
                background={"#D9D9D9"}
                color="#1D1D1D"
                title="Back"
                onClick={onClickBack}
              />
            )}
            {page + 1 < length(pages) && (
              <ButtonComponent
                background={
                  page < length(pages) ? blueButtonColor : greenButtonColor
                }
                disabled={disableNextButton}
                title={
                  page < length(pages)
                    ? "Save and Continue"
                    : "Save and Complete"
                }
                onClick={onClick}
              />
            )}
            {page + 1 >= length(pages) && !isMobile && (
              <ButtonComponent
                background={greenButtonColor}
                onClick={onClick}
                title={"Go to Dashboard"}
              />
            )}
          </Stack>
          {page + 1 >= length(pages) && isMobile && <MobileAppStack />}
        </Stack>
      </BasePageComponent>
    );
}

export default OnboardingPage