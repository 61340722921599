import { join, keysIn, length, map } from "ramda";
import { useNavigate, useSearchParams } from "react-router-dom"


const useDoNewTab = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setSearchParams] = useSearchParams();

    return (route, params = {}) => {
        const pairs = map((key) => `${key}=${params[key]}`, keysIn(params))
        const paramString = length(pairs) > 0 ? `?${join("&",pairs)}` : ""
        window.open(`${window.location.origin}/${route}${paramString}`, '_blank')
    }
}


export default useDoNewTab 