import { useEffect, useState } from "react"
import BasePageComponent from "../../components/base-page-component"
import TableComponent from "../../components/table-component"
import { dashboardRoute } from "../../utils/page-routes"
import { useSelector } from "react-redux"
import { selectCurrentClubId, selectCurrentClubName } from "../../redux/selectors"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathLeaderboard } from "../../utils/endpoint-paths"
import { equals,reduce } from "ramda"
import { Box } from "@mui/material"

const LeaderboardPage = () => {


    const { generateEndpoint, doGet } = useApiUtilsContext()
    const clubId = useSelector(selectCurrentClubId)
    const clubName = useSelector(selectCurrentClubName)

    const [leaderboard, setLeaderboard] = useState([])
    const [userRanking, setUserRanking] = useState()
    const leaderboardEndpoint = generateEndpoint(apiPathLeaderboard(clubId))


    const mappedLeaderboard = reduce((acc, item) => {
        let you = false
        let name = item?.name
        if (item?.rank === userRanking?.rank) {
            name = `${name} (You)`
            you = true
        }

        let out = {
                section: `Everyone in ${clubName}`,
                rank: item?.rank,
                name: name,
                hours_comp: item?.hours_comp,
                hours_remaining: item?.hours_remaining,
                hours_committed: item?.hours_committed,
                percentage: (100 * (item?.percent_comp ?? 0)).toFixed(1) + "%",
            }
        
        
        acc = [...acc, out]

        if (you) {
            let newOut = {...out}
            newOut.section = "Your Rank"
            acc = [...acc, newOut]
        }

        return acc
    }, [])(leaderboard)


    useEffect(() => {
        const fetchActiveMembers = async () => {
            try {
                const response = await doGet({ endpoint: leaderboardEndpoint, queryParams: { limit: 1000 } })
                setLeaderboard(response?.data?.leaderboard ?? [])
                setUserRanking(response?.data?.user)
            } catch (error) {
                console.error(error)
            }
        }

        if (clubId) {
            fetchActiveMembers()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])

    return <BasePageComponent
        pageTitle={"Leaderboard"}
        backRoute={dashboardRoute}
        width="100%"
    >
        <Box marginX="50px" marginTop="10px">
        <TableComponent
            items={mappedLeaderboard}
            groupBy={"section"}
            groupSort={(a,b) => {
                if (equals(a, "Your Rank")) {
                    return 0
                } else {
                    return 1
                }
            }}
            columns={[
                { key: "rank", title: "Rank" },
                { key: "name", title: "Name" },
                { key: "hours_comp", title: "Hours Completed" },
                { key: "hours_committed", title: "Hours Committed" },
                { key: "hours_remaining", title: "Hours Remaining" },
                { key: "percentage", title: "% Completed" },
            ]
            }


        />
        </Box>
        


    </BasePageComponent>


}

export default LeaderboardPage