import { anyPass, isEmpty} from "ramda"
import { invertFunction } from "./invert-function"


export const isUndefined = (value) => value === undefined
export const isNull = (value) => value === null


export const isNullOrUndefinedOrEmpty = anyPass([isUndefined, isNull, isEmpty])


export const isNotUndefined = invertFunction(isUndefined)
export const isNotNull = invertFunction(isNull)
export const isNotNullOrUndefinedOrEmpty = invertFunction(isNullOrUndefinedOrEmpty)