import axios from "axios"
import { getBaseUrl } from "../utils/envs"
import { compose, last, replace, split, startsWith } from "ramda"
import { createContext, useContext, useState } from "react"
import { apiPathGetFile, apiPathUploadFile } from "../utils/endpoint-paths"

const ApiContext = createContext({})

const ApiUtilsContextProvider = ({ children }) => {

    const [token, setToken] = useState()
    
    const isTokenSet = () => !!token


    const doRequest = ({ endpoint, method, queryParams, body, contentType = "application/json" }) => {

        if (!token) {
            console.warn("Tried to make call before token was set, returning!")
            return
        }

        let requestConfig = {
            method,
            url: endpoint,
            params: queryParams ?? {},
            headers: {
                'Content-Type': contentType,
                accept: 'application/json',
                Authorization: `bearer ${token}`,
            },
        }
        if (body) {
            requestConfig = {
                ...requestConfig,
                data:body
            }
        }
        return axios(requestConfig)
    }

    const generateEndpoint = (route, version = "v1") => {
        if (startsWith("/")(route)) {
            route = replace("/", "", route)
        }
        return `${getBaseUrl()}/${version}/${route}`
    }

    const doGet = async ({ endpoint, queryParams }) => {
        return doRequest({
            method: "GET",
            endpoint,
            queryParams: queryParams ?? {},
            body: undefined
        })

    }

    const doPost = async ({ endpoint, body, queryParams, contentType }) => {
        return doRequest({
            method: "POST",
            body,
            contentType,
            endpoint,
            queryParams: queryParams ?? {}
        })
    }

    const doPut = async ({ endpoint, body, queryParams }) => {
        return doRequest({
            method: "PUT",
            endpoint,
            body,
            queryParams: queryParams ?? {}
        })
    }

    const doDelete = async ({endpoint, queryParams}) => {
        return doRequest({
            method: "DELETE",
            endpoint,
            queryParams: queryParams ?? {},
            body: undefined
        })
    }

    const uploadFile = async (formData, bucket) => {
        const endpoint = generateEndpoint(apiPathUploadFile(bucket))

        const response = await doPost({endpoint, body:formData, contentType:"multipart/form-data"})
        const newLocation = response?.headers?.location

        return compose(replace("media",""), last, split("/"))(newLocation)

    }

    const uploadPfp = async (formData) => uploadFile(formData, "pfp")
    const uploadQualification = async (formData) => uploadFile(formData, "qual")

    const getImageUrl = minioId => generateEndpoint(apiPathGetFile("pfp", minioId))
    const getQualUrl = minioId => generateEndpoint(apiPathGetFile("qual", minioId))

    const value = {
        setToken,
        generateEndpoint,
        doGet,
        doPost,
        doPut,
        doDelete,
        isTokenSet,
        uploadFile,
        uploadPfp,
        uploadQualification,
        getImageUrl,
        getQualUrl
    }

    return <ApiContext.Provider value={value}>
        {children}
    </ApiContext.Provider>

}


export default ApiUtilsContextProvider

export const useApiUtilsContext = () => useContext(ApiContext)