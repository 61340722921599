import { Stack } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import ButtonComponent from "../../components/button-component"
import { Add } from "@mui/icons-material"
import { editTeamRoute } from "../../utils/page-routes"
import TableComponent, { columnTypeString } from "../../components/table-component"
import { useEffect, useState } from "react"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathClubTeamsList } from "../../utils/endpoint-paths"
import { endsWith, length, map, reduce } from "ramda"
import { useSelector } from "react-redux"
import { selectCurrentClubId, selectUserPersonId } from "../../redux/selectors"
import { newFormEntityId } from "../../utils/constants"
import { useIsAdmin } from "../../utils/user-hooks"
import SearchBarComponent from "../../components/search-bar-component"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import useDoNavigate from "../../utils/do-navigate"

const TeamsPage = () => {

    const [items, setItems] = useState()
    const { generateEndpoint, doGet } = useApiUtilsContext()

    const doNavigate = useDoNavigate()

    const userMemberId = useSelector(selectUserPersonId)

    const currentClubId = useSelector(selectCurrentClubId)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    const [searchDisplayValue, setSearchDisplayValue] = useState()
    const [searchValue, setSearchValue] = useState()

    const getTeamsEndpoint = generateEndpoint(apiPathClubTeamsList(currentClubId))


    const isAdmin = useIsAdmin()


    const mapMembers = reduce((acc, member) => {

        let name = member?.member?.fullName ?? member?.member?.email

        if (endsWith("...")(acc)) {
            return acc
        }

        if (length(acc) !== 0) {
            name = `, ${name}`
        }
        if (length(`${acc}${name}`) < 40) {
            acc = `${acc}${name}`
        } else {
            acc = `${acc}, ...`
        }


        return acc
    }, "")

    const mapTeams = (data) => {
        return {
            id: data?.id,
            name: data?.details?.name,
            description: data?.details?.description,
            memberCount: length(data?.members ?? []),
            members: mapMembers(data?.members ?? ["N/A"])
        }
    }

    const onCreateTeam = () => {
        doNavigate(editTeamRoute, {target: newFormEntityId})
    }

    const onSelectTeam = (team) => {
        doNavigate(editTeamRoute, {target: team.id})
    }

    useEffect(() => {

        const getTeamsList = async () => {
            try {
                let queryParams = {}
                if (!isAdmin) {
                    queryParams = {
                        "member-id": userMemberId
                    }
                }
                if (isNotNullOrUndefinedOrEmpty(searchValue)) {
                    queryParams = {
                        "search_text":searchValue
                    }
                }
                const response = await doGet({ endpoint: getTeamsEndpoint, queryParams })
                setItems(map(mapTeams)(response?.data))
            } catch (error) {
                console.error(error)
                setError(error?.response?.status ?? "???")
            }
            setLoading(false)
        }


        if (currentClubId) {
            getTeamsList()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, searchValue])


    useEffect(() => {
        const debounce = setTimeout(() => {
            setSearchValue(searchDisplayValue)
        }, 500)

        return () => clearTimeout(debounce)
    })

    return <BasePageComponent
        pageTitle={"Teams"} width="100%"
        marginRight="50px"
        inlineContent={<Stack direction={"row"} marginY={"40px"} justifyContent="space-between" width="100%" paddingLeft={"20px"}>
            <SearchBarComponent
                placeholder="Search for Team Name..."
                searchValue={searchDisplayValue}
                setSearchValue={setSearchDisplayValue}
            />
            {isAdmin &&
                <ButtonComponent
                    title="Add New Team"
                    icon={<Add />}
                    onClick={onCreateTeam}
                />
            }
        </Stack>}

    >

        <Stack direction={"column"} sx={{
            marginX: "50px",
            marginTop: "20px"
        }}>

            <TableComponent
                loading={loading}
                error={error}
                items={items ?? []}
                onRowClick={onSelectTeam}
                columns={[
                    { key: "name", title: "Team Name", type: columnTypeString },
                    { key: "description", title: "Description", type: columnTypeString },
                    { key: "memberCount", title: "No. Members", type: columnTypeString },
                    { key: "members", title: "Members", type: columnTypeString }
                ]}
            />
        </Stack>

    </BasePageComponent>

}

export default TeamsPage