import { Chip, Input, Stack, Grid } from "@mui/material"
import FieldLabel from "../field-label"
import { addIndex, init, length, map, remove } from "ramda"
import { useState } from "react"
import { isNotEmpty } from "../../../utils/functionals"


const MultiValueField = ({ label, value = [], setValue, mandatory=false }) => {

    const [newValue, setNewValue] = useState("")

    const onAddValue = () => {
        if (isNotEmpty(newValue)) {
            setValue([...value, newValue])
            setNewValue("")
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
           onAddValue()
        } else if (e.keyCode === 8) {
            if (length(newValue) === 0) {
                setValue([...init(value)])
                setNewValue("")
                e.preventDefault()
            }
        }
    }

    const onDelete = (index) => {
        setValue(remove(index, 1, value))
    }


    return <Stack spacing={2} width={"50ch"}>
        <FieldLabel label={label} mandatory={mandatory}/>
        <Grid container>
            {addIndex(map)((v, index) => <Grid item key={index}>
                <Chip label={v} onDelete={() => onDelete(index)} />
            </Grid>)(value)}
        </Grid>
        <Input
            value={newValue}
            onBlur={() => onAddValue()}
            onChange={(e) => setNewValue(e.target.value)}
            onKeyDown={onKeyDown}
        />

    </Stack>

}


export default MultiValueField