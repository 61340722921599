import { Autocomplete, Chip, Stack, TextField } from "@mui/material"
import FieldLabel from "../field-label"
import { compose, equals, find, join, map, prop } from "ramda"
import ReadOnlyField from "../readonly-field"


const ChipSelectField = ({
    value,
    setValue,
    options = [],
    id,
    label,
    spacing = 2,
    readOnly = false,
    getOptionLabel = (option) => option?.name,
    isOptionEqualToValue = (a, b) => equals(a?.id)(b?.id),
    maxWidth = "50ch",
    mandatory=false

}) => {


    const mappedValues = map(v => {

        const vId = v?.id ?? v

        return find((item) => isOptionEqualToValue({id: vId},item))(options ?? [])

    })(value)

    return <Stack direction="column" spacing={spacing} maxWidth={maxWidth}>
        <FieldLabel label={label} mandatory={mandatory}/>
        {readOnly ?
            <ReadOnlyField value={mappedValues} parseValue={compose(join(", "), map(prop("name")))}/>
        : 
        <Autocomplete
            id={id}
            multiple
            readOnly={readOnly}
            value={mappedValues}
            onChange={(event, newValue) => setValue(newValue)}
            isOptionEqualToValue={isOptionEqualToValue}
            options={options}
            getOptionLabel={getOptionLabel}
            sx={{
                maxWidth: maxWidth
            }}
            renderTags={(value, getTagProps) => value.map((option, index) => <Chip
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
            />

            )}
            renderInput={(params) =>
                <TextField

                    variant="standard"
                    {...params}
                />
            }
        />}


    </Stack>


}

export default ChipSelectField