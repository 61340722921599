import { join, keysIn, length, map } from "ramda";
import { useNavigate, useSearchParams } from "react-router-dom"


const useDoNavigate = () => {
    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const [_, setSearchParams] = useSearchParams();

    return (route, params = {}) => {
        //setSearchParams(params)

        const pairs = map((key) => `${key}=${params[key]}`, keysIn(params))

        const paramString = length(pairs) > 0 ? `?${join("&",pairs)}` : ""

        navigate(`${route}${paramString}`)
    }
}


export default useDoNavigate