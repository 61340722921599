import {  Stack } from "@mui/material"
import { fieldWidth } from "../../../styles/style-constants"
import FieldLabel from "../field-label"
import { DatePicker } from "@mui/x-date-pickers"
import ReadOnlyField from "../readonly-field"
import { formatDateShort } from "../../../utils/helper-functions/datetime-functions"


const DatePickerField = ({
    label, 
    value, 
    setValue, 
    width, 
    spacing=2, 
    readOnly=false, 
    mandatory=false,
    minDate,
    maxDate
}) => {

    return <Stack spacing={spacing} width={fieldWidth}>
        <FieldLabel label={label} mandatory={mandatory}/>
        {readOnly ? <ReadOnlyField value={value ?? ""} parseValue={formatDateShort}/> :
        <DatePicker 
            minDate={minDate}
            maxDate={maxDate}
            readOnly={readOnly}
            value={value ?? {}} 
            onChange={(value) =>{
                setValue(value)
            }}
            format="DD/MM/YYYY"
            slotProps={{
                textField:{
                    sx:{
                        width:width
                    },
                    variant:"standard"
                }
            }}
        />
}
    </Stack>


}


export default DatePickerField