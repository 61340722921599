import BasePageComponent from "../../components/base-page-component"
import CardContainer from "../../components/card-container"

const NoClubsPage = () => {
    return <BasePageComponent pageTitle={"No clubs!"} onboardRedirect={false}>
        <CardContainer>
            Sorry, you are not a member in any clubs in our system! <br/>
            Please logout and contact your club administrator.
        </CardContainer>
    </BasePageComponent>
}

export default NoClubsPage