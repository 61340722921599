import { useSelector } from "react-redux"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"

import { greenButtonColor } from "../../../styles/style-constants"

import { useEffect } from "react"
import { ThumbUp } from "@mui/icons-material"

const getBodyOpener = (name) => `Hey ${name}, `
const getBodyText = (delegateeName) => `Good News! ${delegateeName} accepted your delegation for the following Volunteer Event:`

const getMemberFooterText = () => "Thank you for finding a replacement and helping keeping this event up and running!"
const getAdminFooterText = () =>  "No further action is required from you for this Activity at this stage, we will let you know if circumstances change!"


const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)



    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))


    const delegateeName = activity?.clubMember?.fullName ?? activity?.clubMember?.email ?? "..."

    useEffect(() => {



        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + getBodyText(delegateeName)}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        image={<ThumbUp fontSize="large" htmlColor={greenButtonColor} />}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />

}
const Page = ({ notification }) => {
    const name = useSelector(selectUserFirstName)


    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))
    const delegateeName = activity?.clubMember?.fullName ?? activity?.clubMember?.email ?? "..."

    return (
      <NotificationPageBase
        notification={notification}
        image={<ThumbUp fontSize="large" htmlColor={greenButtonColor} />}
        getBodyOpener={() => getBodyOpener(name)}
        getBodyText={() => getBodyText(delegateeName)}
        getBody={() => (
          <NotificationEventDetailsList
            event={event}
            activity={activity}
            showLocation
            showTime
            showActivity
          />
        )}
        getFooter={() =>
          !notification?.metadata?.is_admin
            ? getMemberFooterText()
            : getAdminFooterText()
        }
      />
    );

}
const NotificationDelegationAccepted = {
    ListItem,
    Page
}

export default NotificationDelegationAccepted