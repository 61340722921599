import { compose, head, isEmpty, keysIn, not } from "ramda";


/**
 * Inverse of of isEmpty.
 */
export const isNotEmpty = compose(not, isEmpty)

/**
 * Returns the key of first element of an object.
 */
export const headKeyIn = compose(head, keysIn)