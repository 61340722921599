import { useSelector } from "react-redux"
import BasePageComponent from "../../components/base-page-component"
import { selectCurrentClubId } from "../../redux/selectors"
import { all, any, append, assocPath, equals, find, findIndex, includes, isEmpty, map, none, reduce, reject, remove, update } from "ramda"
import { editMembersRoute } from "../../utils/page-routes"
import CardContainer from "../../components/card-container"
import FieldLabel from "../../components/fields/field-label"
import { blueButtonColor } from "../../styles/style-constants"
import { Typography, Box } from "@mui/material"
import FormTableComponent from "../../components/form-table-component"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathAddBulkQual, apiPathGetMemberQuals, apiPathGetQualificationTypeList } from "../../utils/endpoint-paths"
import { useEffect, useState } from "react"
import { columnTypeBoolean, columnTypeDate, columnTypeDocument } from "../../components/table-component"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty"
import SaveCancelStack from "../../components/buttons/save-cancel-stack"
import dayjs from "dayjs"
import useDoNavigate from "../../utils/do-navigate"
import { useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setCurrentClubMemberQuals } from "../../redux/app-slice"

const AddQualPage = () => {

    const clubId = useSelector(selectCurrentClubId)
    const dispatch = useDispatch();
    const dispatchMemberQual = (qual) => dispatch(setCurrentClubMemberQuals(qual))

    const { generateEndpoint, doGet, uploadQualification, doPost } = useApiUtilsContext()
    const doNavigate = useDoNavigate()


    // eslint-disable-next-line no-unused-vars
    const [searchParams, _] = useSearchParams();
    const memberId = searchParams.get("qual_member_id")
    const memberUuid = searchParams.get("target")

    const [loading, setLoading] = useState(false)
    const [selectedQuals, setSelectedQuals] = useState([])
    const [qualList, setQualList] = useState([])
    const [existingQuals, setExistingQuals] = useState([])
    const [uploadedQuals, setUploadedQuals] = useState([])


    const getQualById = id => find((qual) => equals(qual?.id)(id))(qualList)

    const qualEndpoint = generateEndpoint(apiPathGetQualificationTypeList(clubId))
    const memberQualsEndpoint = generateEndpoint(apiPathGetMemberQuals(memberId))
    const uploadQualEndpoint = generateEndpoint(apiPathAddBulkQual(memberId))
    

    const mapUserQualType = (qual) => {
        return qual?.type?.id
    }

    const mapQualList = (qual) => {
        return {
            id: qual?.id,
            name: qual?.details?.name,
            description: qual?.details?.description,
            disabled: includes(qual?.id)(existingQuals)
        }
    }

    const getQualTypeList = async () => {

        const response = await doGet({ endpoint: qualEndpoint })
        setQualList(response?.data ?? [])
    }


    const getUserQuals = async () => {
        const response = await doGet({ endpoint: memberQualsEndpoint })
        setExistingQuals(map(mapUserQualType)(response?.data ?? []))
    }


    const mapUploadQuals = (qual) => {
        return {
            id: qual?.id,
            name: qual?.details?.name,
            document: {},
            expiry: {}
        }
    }


    const onUpdate = (qual, _, value) => {
        const qualId = qual?.id
        setSelectedQuals((prev) => {
            let out = prev
            if (includes(qualId)(selectedQuals) && !value) {
                out = remove(findIndex(equals(qualId))(prev), 1)(prev)
            } else if (!includes(qualId)(selectedQuals) && value) {
                out = append(qualId, prev)
            }

            setUploadedQuals((prevUploaded) => {

                let uploadedOut = prevUploaded
                uploadedOut = reject((qual) => !includes(qual?.id)(out))(uploadedOut)
                uploadedOut = reduce((acc, elem) => {
                    if (none((qual) => equals(qual?.id)(elem))(acc)) {
                        acc = [...acc, mapUploadQuals(getQualById(elem))]
                    }
                    return acc
                }, uploadedOut)(out)

                return uploadedOut

            })

            return out
        })
    }

    const onUpdateUploaded = (qualification, path, value) => {
        const { id: qualId } = qualification

        const qualIndex = findIndex((item) => equals(item?.id)(qualId))(uploadedQuals)

        setUploadedQuals((prev) => update(qualIndex, assocPath(path, value, qualification))(prev))
    }

    const onSubmitQualifications = async () => {
        setLoading(true)

        try {
            let certsToUpload = []
            let qualsToPost = []


            for (let index in selectedQuals) {
                const qual = find((qual) => equals(qual?.id)(selectedQuals[index]))(qualList)
                let qualObj = qual
                if (qualObj?.needsCertification) {
                    const uploadQual = find((uploadQual) => equals(uploadQual?.id)(qual?.id))(uploadedQuals)
                    qualObj = { ...qual, ...uploadQual }
                    certsToUpload = [...certsToUpload, qualObj]
                }
                qualsToPost = [...qualsToPost, qualObj]
            }
            let result = {}
            for (let index in certsToUpload) {
                let formData = certsToUpload[index]?.document?.formData
                result[certsToUpload[index]?.id] = await uploadQualification(formData)
            }

            const body = map((qual) => {
                let other = {}
                if (qual?.needsCertification) {
                    other.uploadId = result[qual?.id]
                    other.expiry = dayjs(qual?.expiry).format("YYYY-MM-DD")
                }
                return {
                    typeId: qual?.id,
                    ...other
                }
            })(qualsToPost)

            await doPost({ endpoint: uploadQualEndpoint, body })
            dispatchMemberQual({ type: qualsToPost });
            
        } catch (error) {
            console.error(error)
        }
        doNavigate(editMembersRoute, {target: memberUuid})
        setLoading(false)
    }


    useEffect(() => {

        if (clubId && memberId) {
            getQualTypeList()
            getUserQuals()
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])


    const mappedQualList = map(mapQualList)(qualList)

    const needsCertification = any((qualId) => getQualById(qualId)?.needsCertification
    )(selectedQuals)

    const canSubmit = all(uploadedQual =>
        !uploadedQual?.needsCertification || (
            isNotNullOrUndefinedOrEmpty(uploadedQual?.document) &&
            isNotNullOrUndefinedOrEmpty(uploadedQual?.expiry)
        )
    )(uploadedQuals)

    return <BasePageComponent
        pageTitle={"Add Qualifications"}
        backRoute={editMembersRoute}
        backEntity={memberUuid}
        snackbarLoading={loading}
        clearEditCache={false}

        inlineContent={<SaveCancelStack
            disableSave={!canSubmit || isEmpty(selectedQuals)}
            cancelAction={() => {
                doNavigate(editMembersRoute, {target:memberUuid})
            }}
            saveAction={() => onSubmitQualifications()}

        />}
    >

        <CardContainer padding="20px">
            <FieldLabel label={"Qualifications"} color={blueButtonColor} />
            <Typography marginTop={2}>
                Which qualifications would you like to add? (Please select all that applies)
            </Typography>
            <FormTableComponent
                marginTop={2}
                readOnly
                items={mappedQualList}
                onUpdate={onUpdate}
                columns={[
                    { title: "Qualification Type", getValue: row => row?.name },
                    { title: "Description", getValue: row => row?.description },
                    {
                        title: "",
                        getValue: row => includes(row?.id)(selectedQuals),
                        canEdit: true,
                        type: columnTypeBoolean,
                        readOnlyOverride: true
                    }
                ]}
            />
            <Box marginTop={2} />
            {needsCertification && <>
                <FieldLabel label={"Certifications"} color={blueButtonColor} />
                <Typography marginTop={2}>
                    Some of the selected qualifications need certification, please provide proof of certification (i.e. copy or picture of certificate of attainment) for the following qualifications:
                </Typography>
                <FormTableComponent
                    marginTop={2}
                    readOnly
                    items={uploadedQuals ?? []}
                    onUpdate={onUpdateUploaded}
                    columns={[
                        {
                            title: "Qualification Type",
                            getValue: row => row?.name
                        },
                        {
                            title: "Proof of Certification",
                            readOnlyOverride: true,
                            type: columnTypeDocument,
                            getValue: row => row?.document,
                            setPath: ["document"],
                        },
                        {
                            title: "Expiry Date",
                            readOnlyOverride: true,
                            type: columnTypeDate,
                            setPath: ["expiry"],
                            getValue: row => row?.expiry
                        }
                    ]}
                />
            </>}
        </CardContainer>

    </BasePageComponent>

}

export default AddQualPage