import { Stack, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import FieldLabel from "../field-label";
import ReadOnlyField from "../readonly-field";
import { fieldWidth } from "../../../styles/style-constants";

const BooleanField = ({
    value,
    setValue,
    id,
    label,
    spacing = 2,
    readOnly = false,
    maxWidth,
    disabled = false,
    mandatory = false,
    trueLabel = "Yes",
    falseLabel = "No",
    flipOptions = false,
}) => {
    return (
        <Stack direction="column" spacing={spacing} maxWidth={maxWidth ?? fieldWidth} >
            <FieldLabel label={label} maxWidth={maxWidth ?? fieldWidth} disabled={disabled} mandatory={mandatory} />
            {readOnly ? <ReadOnlyField value={value} parseValue={value ? trueLabel : falseLabel} /> :
                <RadioGroup
                    id={id}
                    row
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                >
                    <FormControlLabel 
                        disabled={readOnly || disabled} 
                        value={flipOptions ? false : true} 
                        control={<Radio />} 
                        label={flipOptions ? falseLabel : trueLabel} 
                        />
                    <FormControlLabel 
                        disabled={readOnly || disabled} 
                        value={flipOptions ? true : false} 
                        control={<Radio />} 
                        label={flipOptions ? trueLabel : falseLabel} 
                    />
                </RadioGroup>}
        </Stack>
    );
};

export default BooleanField;