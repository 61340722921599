import { Search } from "@mui/icons-material"
import { InputAdornment, TextField } from "@mui/material"

const SearchField = ({placeholder="Search...", width="100%", value, setValue}) => {

    return <TextField
        placeholder={placeholder}
        sx={{width:width}}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        InputProps={{
            endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
        }}
    />

}

export default SearchField