
import DropdownField from "../dropdown-field"


export const homeAwayOptions = [
    { value: "home", label: "H" },
    { value: "away", label: "A" }
]

const HomeAwayField = ({ label = "Home / Away", value, setValue, readOnly = false, mandatory=false }) => {

    return <DropdownField
        mandatory={mandatory}
        label={label}
        value={value}
        setValue={setValue}
        readOnly={readOnly}
        options={homeAwayOptions} />

}

export default HomeAwayField