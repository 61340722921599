import { FileDownload } from "@mui/icons-material";
import ButtonComponent from "../button-component";
import { useApiUtilsContext } from "../../providers/api-utils-provider";

const DownloadButton = ({ disabled = false, title = "View", icon = <FileDownload />, uuid, bucket, background }) => {

    const { getQualUrl } = useApiUtilsContext()


    const getFile = async () => {
        const endpoint = getQualUrl(uuid)
        window.open(endpoint, "_blank", "norefferer")

    }


    return <ButtonComponent
        disabled={disabled}
        disabledBackground={"#B1B1B1"}
        background={background}
        icon={icon}
        title={title}
        onClick={() => getFile()}
    />
}

export default DownloadButton