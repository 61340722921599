import { Box, Grid, Typography } from "@mui/material"
import CardContainer from "../../components/card-container"
import FieldLabel from "../../components/fields/field-label"
import { blueButtonColor } from "../../styles/style-constants"
import { useSelector } from "react-redux"
import { selectCurrentClubName } from "../../redux/selectors"
import PhotoBox from "../../components/photo-box-component"

const OnboardingFinalPage = ({ clubImageId, initial }) => {

    const clubName = useSelector(selectCurrentClubName)
    
    return <CardContainer padding="20px" marginTop="15px">
        <Grid container columns={3}>

            <Grid item xs={2} minWidth="200px">
                <Typography fontSize="24px">Success!</Typography>
                <Typography marginBottom={2}>
                    You have successfully joined {clubName}!
                </Typography>
                <FieldLabel label={"What's Next?"} color={blueButtonColor} />
                <Box marginTop={2}>
                    {initial ?
                        <ol>
                            <li>
                                Please download the MemberClubTeams App from the applicable app store:
                                <ol type="i">
                                    <li>iOS: link</li>
                                    <li>Android: link</li>
                                </ol>
                            </li>
                            <li>
                                Use your email and password to log into the {clubName} system.
                            </li>
                            <li>
                                To learn about how to use the MemberClubTeams App, please view our user guide: link
                            </li>
                            <li>
                                For issues with access, please contact admin@memberclubteams.io
                            </li>
                        </ol> : <ol>
                            <li>Login to the MemberClubTeams App with your current email and password</li>
                            <li>To access the {clubName} system, please use the top-left navigation menu and click 'Switch Clubs'. </li>
                        </ol>}
                </Box>
            </Grid>
            <Grid item xs={1}>
                <PhotoBox readOnly minioId={clubImageId} label="" />
            </Grid>

        </Grid>

    </CardContainer>


}



export default OnboardingFinalPage