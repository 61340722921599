import { toLower, defaultTo, path } from "ramda";

const memberSortingKey = (member) => {
  const firstName = path(["member", "firstName"], member);
  const email = path(["member", "email"], member);
  if (firstName) {
    return firstName;
  } else {
    return email;
  }
};

export const sortTeamMembersInclusive = (teamMembers, userUuid) =>
  teamMembers.sort((a, b) => {
    if (a?.member?.uuid === userUuid) return -1;
    if (b?.member?.uuid === userUuid) return 1;

    const compA = toLower(defaultTo("", memberSortingKey(a)));
    const CompB = toLower(defaultTo("", memberSortingKey(b)));
    return compA.localeCompare(CompB);
  }) ?? [];
  

  export const sortTeamMembersExclusive = (teamMembers) =>
  teamMembers.sort((a, b) => {
    const compA = toLower(defaultTo("", memberSortingKey(a)));
    const CompB = toLower(defaultTo("", memberSortingKey(b)));
    return compA.localeCompare(CompB);
  }) ?? [];
  