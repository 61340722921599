import { isNullOrUndefinedOrEmpty } from "../../../utils/helper-functions/is-null-or-undefined-or-empty";
import { ButtonBase, Typography, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ActivityActionsStack = ({
  row,
  onVolunteer,
  userUuid,
  isVolunteerLoading,
  checkMemberQuals,
}) => {
  if (isNullOrUndefinedOrEmpty(row?.clubMember)) {
    const canVolunteer = checkMemberQuals(row);
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <ButtonBase
          sx={{
            background: "#1890FF",
            borderRadius: "4px",
            color: "white",
            padding: "5px",
            paddingX: "10px",
            height: 28,
            width: 132,
            "&:disabled": {
              background: "rgba(24, 144, 255, 0.2)",
            },
          }}
          onClick={() => onVolunteer(row)}
          disabled={isVolunteerLoading || !canVolunteer}
        >
          <Typography fontFamily={"mulish"} fontSize={14} fontWeight="bold">
            Volunteer
          </Typography>
        </ButtonBase>
        {!canVolunteer && (
          <Tooltip
            title="You do not meet the required qualifications to volunteer for this activity"
            sx={{}}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#323E59",
                  "& .MuiTooltip-arrow": {
                    color: "#323E59",
                  },
                  color: "#FFFFFF",
                },
              },
            }}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <InfoOutlinedIcon
              style={{
                color: "#1890FF",
              }}
            />
          </Tooltip>
        )}
      </Stack>
    );
  } else {
    if (userUuid === row?.clubMember?.uuid) {
      return (
        `${row?.clubMember.fullName} (You)` || `${row?.clubMember?.email} (You)`
      );
    } else {
      return row?.clubMember.fullName || row?.clubMember?.email;
    }
  }
};

export default ActivityActionsStack;
