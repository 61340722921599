import { Box, Container, Divider, List, ListItem, ListItemButton, Typography } from "@mui/material"
import CardContainer from "../card-container"
import { blueButtonColor } from "../../styles/style-constants"
import SearchField from "../fields/search-field"
import { map } from "ramda"

const ObjectListComponent = ({ 
    title, 
    searchPlaceholder, 
    items = [], 
    onSelectItem, 
    getItemKey = (item) => item?.id, 
    getItemName = (item) => item?.name,
    selectedKey,
    searchValue,
    setSearchValue
}) => {


    return <CardContainer
        width="336px"
        padding="0px"
    >
        <Container
            sx={{
                paddingY: "13px",
                width: '100%',
                background: blueButtonColor,
                margin: 0
            }}
        >
            <Typography
                width={"100%"}
                textAlign={"center"}
                color="white"
                fontWeight={500}
                fontSize="14px"
                letterSpacing={"2px"}
                textTransform={"uppercase"}
                lineHeight={"17px"}
            >
                {title}
            </Typography>
        </Container>
        <Box padding={"10px"}>
            <SearchField
                placeholder={searchPlaceholder ?? `Search ${title}...`}
                value={searchValue}
                setValue={setSearchValue}
            />
        </Box>
        <List>
            <Divider />
            {map((item) => <div key={getItemKey(item)}>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => onSelectItem(item)}
                        sx={{ 
                            paddingY: 2,
                            backgroundColor: selectedKey === getItemKey(item) ? '#F3EFFB' : 'transparent'
                        }}
                    >
                        {getItemName(item)}
                    </ListItemButton>
                </ListItem>
                <Divider />
            </div>
            )(items)}
        </List>

    </CardContainer>


}

export default ObjectListComponent