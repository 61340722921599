import { Typography, Stack } from "@mui/material"
import { greyTextColor } from "../../../styles/style-constants"

const FieldLabel = ({ label, color = greyTextColor, disabled = false, mandatory = false, readOnly = false }) => {
    return <Stack maxHeight={label ? undefined : "0px"} direction={"row"}> <Typography
        fontWeight={600}
        fontSize="14px"
        lineHeight="17px"
        letterSpacing={"2px"}
        textTransform={"uppercase"}
        color={color}

        sx={{
            opacity: disabled ? 0.5 : 1.0,
          
        }}
    >
        {label}
    </Typography>
        {(mandatory && !disabled && !readOnly) && <Typography
            fontWeight={600}
            color="red"
            fontSize="14px"
            lineHeight="17px"
            letterSpacing={"2px"}
            textTransform={"uppercase"}>*</Typography>}
    </Stack>
}

export default FieldLabel