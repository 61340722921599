
import DropdownField from "../dropdown-field"

const IndigenousField = ({label="Do you identify as indigenous and/or torres strait islander?", value, setValue, readOnly=false, mandatory=false}) => {
        return <DropdownField 
            label={label} 
            value={value} 
            setValue={setValue}
            readOnly={readOnly}
            mandatory={mandatory}
            maxWidth={"68ch"}
            options={[
            {value:"no", label:"No"},
            {value:"indigenous", label:"Indigenous"},
            {value:"torres", label:"Torres Strait Islander"},
            {value:"indigenoustorres", label:"Indigenous and Torres Strait Islander"}
        ]}/>

}

export default IndigenousField