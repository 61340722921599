import { IconButton, Typography, Avatar } from "@mui/material"
import { useSelector } from "react-redux"
import { selectCurrentClubId, selectCurrentClubName } from "../../redux/selectors"
import { useHasNoClubs } from "../../utils/user-hooks"
import { useEffect, useState } from "react"
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathGetClub } from "../../utils/endpoint-paths"

const ClubAppBarButton = ({ onClick, isMobile }) => {

    const clubId = useSelector(selectCurrentClubId)
    const currentClubName = useSelector(selectCurrentClubName)
    const hasNoClubs = useHasNoClubs()

    const { generateEndpoint, doGet, getImageUrl } = useApiUtilsContext()

    const endpoint = generateEndpoint(apiPathGetClub(clubId))

    const [clubImage, setClubImage] = useState()

    const doClick = () => {
        if (!hasNoClubs) {
            onClick()
        }
    }


    useEffect(() => {

        const getClub = async () => {
            try {
                const response = await doGet({ endpoint })
                const imageId = response?.data?.details?.pictureId
                if (imageId) {
                    setClubImage(getImageUrl(imageId))
                }

            } catch (error) {
                console.error(error)
            }
        }

        getClub()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubId])

    const clubIntitals = currentClubName?.split(" ").reduce((acc, cur) => acc + cur.slice(0, 1).toUpperCase(), "").slice(0, 2);

    return <IconButton color="inherit" onClick={doClick} >

        {!hasNoClubs &&
            <>
                {!isMobile && <Typography paddingRight={1}>
                    {currentClubName ?? "Loading..."}
                </Typography>}
                <Avatar
                    alt={currentClubName}
                    src={clubImage}
                    sx={{
                        height: "35px",
                        width: "35px",
                        fontSize: "16px"
                    }}
                >{clubIntitals}</Avatar> 
            </>}
        {hasNoClubs && <Typography>
            No Clubs!
        </Typography>}

    </IconButton>


}

export default ClubAppBarButton