import { useSelector } from "react-redux"
import { NotificationDelegateDialog, NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { blueButtonColor, greenButtonColor, redButtonColor } from "../../../styles/style-constants"
import { membersRoute } from "../../../utils/page-routes"
import { useEffect, useState } from "react"
import { SwapHorizontalCircle, ThumbDown, ThumbUp } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { Stack } from "@mui/material"
import ButtonComponent from "../../../components/button-component"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { apiPathAcceptDelegation } from "../../../utils/endpoint-paths"


const getBodyOpener = (name) => `Hey ${name}, `
const getBodyText = (delegateeName) => `Unfortunately ${delegateeName} declined your delegation for the following Volunteer Event:`


const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)
    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const delegateeName = notification?.metadata?.volunteer_name ?? "..."

    useEffect(() => {
        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + getBodyText(delegateeName)}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        image={<ThumbDown fontSize="large" htmlColor={redButtonColor} />}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />

}

const Page = ({ notification, refreshNotifications }) => {
    const name = useSelector(selectUserFirstName)

    const [open, setOpen] = useState(false)

    const { generateEndpoint, doPut } = useApiUtilsContext()

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))
    const delegateeName = notification?.metadata?.volunteer_name ?? "..."

    const acceptEndpoint = generateEndpoint(apiPathAcceptDelegation(activity?.id))

    const onAccept = async () => {
        try {
            await doPut({ endpoint: acceptEndpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }

    return (
      <>
        <NotificationDelegateDialog
          open={open}
          setOpen={setOpen}
          name={name}
          notification={notification}
          activity={activity}
          event={event}
          onDelegate={() => refreshNotifications()}
          bodyText={`As ${delegateeName} cannot attend the following Volunteer Event:`}
        />

        <NotificationPageBase
          notification={notification}
          image={<ThumbDown fontSize="large" htmlColor={redButtonColor} />}
          getBodyOpener={() => getBodyOpener(name)}
          getBodyText={() => getBodyText(delegateeName)}
          getBody={() => (
            <NotificationEventDetailsList
              event={event}
              activity={activity}
              showLocation
              showTime
              showActivity
            />
          )}
          getActionsText={() => {
            return (
              notification?.metadata?.is_admin &&
              "You can delegate a new volunteer through the button below:"
            );
          }}
          getActions={() => (
            <Stack direction={"row"} spacing={2}>
              {!notification?.metadata?.is_admin && (
                <ButtonComponent
                  title="Confirm"
                  background={greenButtonColor}
                  icon={<ThumbUp />}
                  onClick={onAccept}
                />
              )}
              <ButtonComponent
                onClick={() => setOpen(true)}
                title="Delegate"
                background={blueButtonColor}
                icon={<SwapHorizontalCircle />}
              />
            </Stack>
          )}
          getFooter={() =>
            !notification?.metadata?.is_admin && (
              <span>
                <span>Can't Find A Replacement? </span>
                <Link to={`${membersRoute}?userTypes=ADMIN`}>
                  Contact A Club Admin
                </Link>
              </span>
            )
          }
        />
      </>
    );

}

const NotificationDelegationDeclined = {
    ListItem,
    Page
}

export default NotificationDelegationDeclined