import { Grid, Stack } from "@mui/material"
import { forEach, includes, keys, map, range } from "ramda"
import { WeekHeader } from "./day-header"
import DayBox from "./day-box"
import { getEventStatus } from "../../utils/helper-functions/get-activity-status-counts"

import { dateFromDateTime } from "../../utils/helper-functions/datetime-functions"





const MonthEventView = ({ selectedDate, events, onDayClick, onDotClick }) => {

    const startOfMonth = selectedDate.startOf('month')
    const startOfWeek = startOfMonth.startOf('week')
    const endOfMonth = selectedDate.endOf('month')
    const endOfWeek = endOfMonth.endOf('week')

    const dayCount = endOfWeek.diff(startOfWeek, 'day')
    const dates = map((days) => startOfWeek.add(days, 'day'))(range(0, dayCount + 1))


    let countsPerDate = {}

    forEach((event) => {
        const status = getEventStatus(event)
        const date = dateFromDateTime(dateFromDateTime(event?.start)).toISOString()
        if (!includes(date, keys(countsPerDate))) {
            countsPerDate[date] = {}
        }
        if (!includes(status, keys(countsPerDate[date]))) {
            countsPerDate[date][status] = 1
        } else {
            countsPerDate[date][status] += 1
        }

    })(events)

    return <Stack direction="column" height="100%">

        <Grid
            container
            columns={7}
        >
            <WeekHeader />
        </Grid>

        <Grid
            container
            columns={7}
            width={"full"}
            height={"100%"}
            justifyContent={"space-evenly"}
        >


            {map((date) => <DayBox
                onClick={onDayClick}
                onDotClick={onDotClick}
                key={date.toISOString()}
                counts={countsPerDate[date.toISOString()]}
                date={date}
                selectedDate={selectedDate} />)(dates)}


        </Grid>

    </Stack>


}

export default MonthEventView