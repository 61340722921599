import { Box, Button, Stack, Typography } from "@mui/material"
import BasePageComponent from "../../components/base-page-component"
import { Check } from "@mui/icons-material"
import ButtonComponent from "../../components/button-component"
import SearchBarComponent from "../../components/search-bar-component"
import { useEffect, useState } from "react"
import { blueButtonColor } from "../../styles/style-constants"
import { useDispatch, useSelector } from "react-redux"
import { selectCachedActivityList, selectCachedEventList, selectCurrentClubId, selectUserPersonId } from "../../redux/selectors"
import useDebounce from "../../utils/use-debounce"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathGetActivity, apiPathGetEvent, apiPathNotificationList, apiPathReadNotification, apiPathUnreadNotification } from "../../utils/endpoint-paths"
import NotificationEventCreated from "./notification-types/notification-event-created"
import { append, equals, find, head, includes, isEmpty, length, map, not, reject, reverse } from "ramda"
import NotificationDelegatedActivity from "./notification-types/notification-delegated-activity"
import { addToActivityCache, addToEventsCache, updateUnreadNotificationCount } from "../../redux/app-slice"
import NotificationDelegationAccepted from "./notification-types/notification-delegation-accepted"
import NotificationDelegationDeclined from "./notification-types/notification-delegation-declined"
import NotificationEventReminder from "./notification-types/notification-event-reminder"
import NotificationVolunteerConfirmation from "./notification-types/notification-volunteer-confirmation"
import NotificationVolunteerEventReminder from "./notification-types/notification-volunteer-event-reminder"
import NotificationEventConfirmAttendance from "./notification-types/notification-event-confirm-attendance"



const notificationTypeEventCreated = "EVENT_CREATED"
const notificationTypeVolunteerConfirmation = "VOLUNTEER_CONFIRMATION"
const notificationTypeDelegationAccepted = "DELEGATION_ACCEPTED"
const notificationTypeDelegationDeclined = "DELEGATION_DECLINED"
const notificationTypeEventReminder = "EVENT_REMINDER"
const notificationTypeDelegatedActivity = "DELEGATED_ACTIVITY"
const notificationTypeVolunteerEventReminder = "VOLUNTEER_EVENT_REMINDER"
const notificationTypeEventConfirmAttendance = "EVENT_CONFIRM_ATTENDANCE"





const FilterButton = ({ title, disabled, onClick }) => {
    return <ButtonComponent
        title={title}
        disabled={disabled}
        onClick={onClick}
        disabledBackground={"#BBDEFB"}
        disabledColor={blueButtonColor}
        color={"#323E59"}
        background={"#DADADA"}
        fontSize="12px"
    />
}


const NotificationPage = () => {

    const personId = useSelector(selectUserPersonId)
    const clubId = useSelector(selectCurrentClubId)

    const { generateEndpoint, doGet, doPut } = useApiUtilsContext()
    const [filterUnread, setFilterUnread] = useState(false)
    const [filterRead, setFilterRead] = useState(false);
    const [displaySearchValue, setDisplaySearchValue] = useState()
    const [searchValue, setSearchValue] = useState()
    const [notificationList, setNotificationList] = useState([])
    const [loading, setLoading] = useState(false)

    const [selectedNotification, setSelectedNotification] = useState()

    const [eventRequestsList, setEventRequestsList] = useState([])
    const [eventLoadingList, setEventLoadingList] = useState([])
    const [activityRequestsList, setActivityRequestsList] = useState([])
    const [activityLoadingList, setActivityLoadingList] = useState([])

    const dispatch = useDispatch()
    const dispatchEventToCache = (event) => dispatch(addToEventsCache(event))
    const dispatchActivityToCache = (activity) => dispatch(addToActivityCache(activity))
    const dispatchUpdateUnread = (unread) => dispatch(updateUnreadNotificationCount(unread))

    const activityCachedList = useSelector(selectCachedActivityList, equals)
    const eventCachedList = useSelector(selectCachedEventList, equals)

    const getNotificationsEndpoint = generateEndpoint(apiPathNotificationList(personId))
    const markAsReadEndpoint = generateEndpoint(apiPathReadNotification(personId))
    const markAsUnreadEndpoint = generateEndpoint(apiPathUnreadNotification(personId))


    const addRequest = (itemId, setter, requestsList, cacheList, loadingList) => {
        if (
            not(includes(itemId)(requestsList)) &&
            not(includes(itemId)(cacheList)) &&
            not(includes(itemId)(loadingList))
        ) {
            setter((prev) => append(itemId, prev))
        }
    }


    const addEventRequest = (eventId) => addRequest(
        eventId,
        setEventRequestsList,
        eventRequestsList,
        eventCachedList,
        eventLoadingList
    )

    const addActivityRequest = (activityId) => addRequest(
        activityId,
        setActivityRequestsList,
        activityRequestsList,
        activityCachedList,
        activityLoadingList
    )

    useDebounce(() => {
        setSearchValue(displaySearchValue)
    }, 500, [displaySearchValue])

    const getCachedItem = async (
        itemId,
        setLoadingList,
        setRequestsList,
        dispatcher,
        endpoint,
    ) => {
        try {
            setLoadingList((prev) => append(itemId, prev))
            const response = await doGet({ endpoint })

            dispatcher(response?.data)

            setLoadingList((prev) => reject((item) => item === itemId)(prev))
            setRequestsList((prev) => reject((item) => item === itemId)(prev))
        } catch (error) {
            console.error(error)
        }
    }

    const getEvent = async (eventId) => getCachedItem(
        eventId,
        setEventLoadingList,
        setEventRequestsList,
        dispatchEventToCache,
        generateEndpoint(apiPathGetEvent(eventId))
    )

    const getActivity = async (activityId) => getCachedItem(
        activityId,
        setActivityLoadingList,
        setActivityRequestsList,
        dispatchActivityToCache,
        generateEndpoint(apiPathGetActivity(activityId))

    )


    const getNotifications = async () => {
        setLoading(true)
        let queryParams = {
            club_id: clubId,
            search_text: searchValue?.length > 0 ? searchValue : undefined
        }
        if (filterUnread) {
            queryParams.read = false
        } else if(filterRead) {
            queryParams.read = true
        }

        try {
            const response = await doGet({ endpoint: getNotificationsEndpoint, queryParams })
            setNotificationList(reverse(response?.data ?? []))
            const unreadCount = response?.data?.filter((i) => !i.read )?.length ?? 0
            dispatchUpdateUnread(unreadCount)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const refreshNotifications = async () => {
        getNotifications()
    }

    const markNotification = async (read, ids) => {
        const endpoint = read ? markAsReadEndpoint : markAsUnreadEndpoint

        try {
            setNotificationList((prev) => {
                return map((notification) => {
                    if (includes(notification?.id, ids)) {
                        return { ...notification, read: read }
                    } else {
                        return notification
                    }
                })(prev)
            })
            await doPut({ endpoint, body: ids })

            
        } catch (error) {
            console.error(error)
        }

    }

    const markAsRead = async (id) => {
        markNotification(true, [id])
    }
    const markAsUnread = async (id) => {
        markNotification(false, [id])
    }

    const markAllAsRead = async () => {
        const ids = map((notification) => notification?.id, notificationList)
        markNotification(true, ids)
    }

    const eventRequestsListLength = length(eventRequestsList)
    const activityRequestsListLength = length(activityRequestsList)


    useEffect(() => {


        if (personId) {
            getNotifications()
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, filterUnread, filterRead, personId])

    useEffect(() => {
        if (eventRequestsListLength !== 0) {
            getEvent(head(eventRequestsList))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventRequestsListLength])

    useEffect(() => {
        if (activityRequestsListLength !== 0) {
            getActivity(head(activityRequestsList))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityRequestsListLength])


    return (
      <BasePageComponent
        pageTitle={"Notifications"}
        inlineContent={
          <Stack direction={"row"} spacing={1}>
            <Button onClick={() => markAllAsRead()}>
              <Stack direction="row" spacing={1}>
                <Check fontSize="small" />
                <Typography fontSize={"12px"} textTransform={"none"}>
                  Mark All As Read
                </Typography>
              </Stack>
            </Button>

            <FilterButton
              title={"All"}
              disabled={!filterUnread && !filterRead}
              onClick={() => {
                setFilterUnread(false);
                setFilterRead(false);
              }}
            />
            <FilterButton
              title={"Unread"}
              disabled={filterUnread}
              onClick={() => {
                setFilterUnread(true);
                setFilterRead(false);
              }}
            />
            <FilterButton
              title={"Read"}
              disabled={filterRead}
              onClick={() => {
                setFilterRead(true);
                setFilterUnread(false);
              }}
            />
          </Stack>
        }
      >
        <Stack direction={"row"} marginTop={"10px"}>
          <Stack marginLeft={"50px"} height={"80vh"}>
            <SearchBarComponent
              placeholder="Search for Notification..."
              setSearchValue={setDisplaySearchValue}
              searchValue={displaySearchValue}
            />

            <Box
              marginLeft="0px"
              marginTop="10px"
              bgcolor={"white"}
              borderRadius={"5px"}
            >
              <Stack direction={"column"}>
                {map((notification) => {
                  return (
                    <NotificationListItem
                      key={notification?.id}
                      notification={notification}
                      markAsRead={markAsRead}
                      markAsUnread={markAsUnread}
                      addEventRequest={addEventRequest}
                      addActivityRequest={addActivityRequest}
                      selected={equals(notification?.id, selectedNotification)}
                      setSelectedNotification={() => {
                        setSelectedNotification(notification?.id);
                        if (!notification?.read) {
                          markAsRead(notification?.id);
                        }
                      }}
                    />
                  );
                }, notificationList)}
                {isEmpty(notificationList ?? []) && !loading && (
                  <Box width={"100%"}>
                    <Typography textAlign={"center"}>
                      {filterUnread
                        ? "No unread notifications!"
                        : filterRead
                        ? "No read notifications!"
                        : "No notifications to show!"}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>

          <NotificationPageItem
            notification={find(
              (notification) => equals(notification?.id, selectedNotification),
              notificationList
            )}
            addEventRequest={addEventRequest}
            addActivityRequest={addActivityRequest}
            refreshNotifications={refreshNotifications}
          />
        </Stack>
      </BasePageComponent>
    );

}

export default NotificationPage





const NotificationListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    selected,
    setSelectedNotification,
    addEventRequest,
    addActivityRequest
}) => {


    const props = {
        notification,
        markAsRead,
        markAsUnread,
        addEventRequest,
        addActivityRequest,
        selected,
        setSelectedNotification
    }

    
    switch (notification?.type) {
        case notificationTypeEventCreated:
            return <NotificationEventCreated.ListItem {...props} />
        case notificationTypeVolunteerConfirmation:
            return <NotificationVolunteerConfirmation.ListItem {...props} />
        case notificationTypeDelegationAccepted:
            return <NotificationDelegationAccepted.ListItem {...props} />
        case notificationTypeDelegationDeclined:
            return <NotificationDelegationDeclined.ListItem {...props} />
        case notificationTypeEventReminder:
            return <NotificationEventReminder.ListItem {...props} />
        case notificationTypeDelegatedActivity:
            return <NotificationDelegatedActivity.ListItem {...props} />
        case notificationTypeVolunteerEventReminder:
            return <NotificationVolunteerEventReminder.ListItem {...props} />
        case notificationTypeEventConfirmAttendance:
            return <NotificationEventConfirmAttendance.ListItem {...props} />
        default:
            return <>{notification?.type}</>
    }
}

const NotificationPageItem = ({
    notification,
    addEventRequest,
    addActivityRequest,
    refreshNotifications
}) => {

    const props = {
        notification,
        addEventRequest,
        addActivityRequest,
        refreshNotifications
    }

    switch (notification?.type) {
        case notificationTypeEventCreated:
            return <NotificationEventCreated.Page {...props} />
        case notificationTypeVolunteerConfirmation:
            return <NotificationVolunteerConfirmation.Page {...props} />
        case notificationTypeDelegationAccepted:
            return <NotificationDelegationAccepted.Page {...props} />
        case notificationTypeDelegationDeclined:
            return <NotificationDelegationDeclined.Page {...props} />
        case notificationTypeEventReminder:
            return <NotificationEventReminder.Page {...props} />
        case notificationTypeDelegatedActivity:
            return <NotificationDelegatedActivity.Page {...props} />
        case notificationTypeVolunteerEventReminder:
            return <NotificationVolunteerEventReminder.Page {...props} />
        case notificationTypeEventConfirmAttendance:
            return <NotificationEventConfirmAttendance.Page {...props} />
        default:
            return <>{notification?.type}</>
    }
}