import { IconButton, Stack, Typography } from "@mui/material"
import { blackTextColor } from "../../styles/style-constants"
import LoadingSnackBarComponent from "../loading-snackbar-component"
import { Navigate} from "react-router-dom"
import { onboardingRoute } from "../../utils/page-routes"
import useIsMobile from "../../utils/helper-functions/use-is-mobile"
import { useDispatch, useSelector } from "react-redux"
import { selectUserOnboarded } from "../../redux/selectors"
import { isNotNullOrUndefinedOrEmpty } from "../../utils/helper-functions/is-null-or-undefined-or-empty";
import { ChevronLeft } from "@mui/icons-material"
import { clearEditMembersCache } from "../../redux/app-slice"
import { useEffect } from "react"
import useDoNavigate from "../../utils/do-navigate"

const BasePageComponent = ({
    pageTitle,
    backRoute,
    backEntity,
    inlineJustify="space-between",
    inlineContent,
    width = 'min-content',
    marginRight = "0px",
    snackbarLoadingMessage,
    snackbarDoneMessage,
    snackbarLoading,
    children,
    onboardRedirect = true,
    clearEditCache = true
}) => {


    const isMobile = useIsMobile()

    const onboarded = useSelector(selectUserOnboarded) ?? false

    const doNavigate = useDoNavigate()

    const dispatch = useDispatch()

    const dispatchClearEditCache = () => dispatch(clearEditMembersCache()) 

    useEffect(() => {
        if (clearEditCache) {
            dispatchClearEditCache()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearEditCache])

    if (onboardRedirect && !onboarded) {
        return <Navigate to={onboardingRoute} replace />
    }

    return <div style={{ width: isMobile ? "100%" : width }}>
        <LoadingSnackBarComponent
            loadingMessage={snackbarLoadingMessage}
            doneMessage={snackbarDoneMessage}
            loading={snackbarLoading}
        />
        <Stack
            direction="row"
            spacing={2}
            justifyContent={inlineJustify}
            marginTop={pageTitle && "20px"}
            marginLeft={"50px"}
            marginRight={!isMobile && marginRight}
        >
            <Stack direction="row" alignItems="center">
                {isNotNullOrUndefinedOrEmpty(backRoute) && <IconButton 
                    size="medium" 
                    onClick={() => {
                        if (isNotNullOrUndefinedOrEmpty(backEntity)) {
                            doNavigate(backRoute, {target: backEntity})
                        } else {
                            doNavigate(backRoute)
                        }
                        
                    }}
                    sx={{padding:"0px", margin:"0px"}}
                    >
                        <ChevronLeft fontSize="large"/>
                    </IconButton>}
                <Typography
                    fontWeight={500}
                    fontSize={"24px"}
                    lineHeight={"29px"}
                    color={blackTextColor}
                >
                    {pageTitle}
                </Typography>
            </Stack>


            {inlineContent}
        </Stack>
        {children}
    </div>
}

export default BasePageComponent