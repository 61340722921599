import { Person } from "@mui/icons-material"
import { Typography, ButtonBase, Stack, Box } from "@mui/material"
import { useSelector } from "react-redux";
import { selectCurrentClubRole, selectUserFullName, selectUserPictureId, selectUserUuid } from "../../redux/selectors";
import { editMembersRoute } from "../../utils/page-routes";
import getMemberTypeName from "../../utils/helper-functions/get-member-type-name";
import { useHasNoClubs } from "../../utils/user-hooks";
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import useDoNavigate from "../../utils/do-navigate";

const UserProfileDrawerButton = ({ onClose }) => {

    const doNavigate = useDoNavigate()

    const {getImageUrl} = useApiUtilsContext()

    const name = useSelector(selectUserFullName)
    const uuid = useSelector(selectUserUuid)
    const role = useSelector(selectCurrentClubRole)
    const userImage = useSelector(selectUserPictureId)

    const hasNoClubs = useHasNoClubs()

    const onClick = () => {
        if (!hasNoClubs) {
            onClose()
            doNavigate(editMembersRoute, {target:uuid})
        }
    }


    return <ButtonBase
        onClick={onClick}
        sx={{ borderRadius: 2 }}
    >
        <Stack direction="row" alignItems={"center"} justifyContent={"flex-stat"} width={"80%"} spacing={2}>
            {userImage ? <Box
                component="img"
                alt="UserProfile"
                src={getImageUrl(userImage)}
                sx={{
                    borderRadius: "100%",
                    objectFit: "cover",
                    height: "40px",
                    width:"40px"
                }}
            /> : <Person fontSize={"large"} />}
            <Stack direction="column" alignItems={"flex-start"}>
                <Typography fontSize={16} fontWeight={500}>
                    {name ?? "Loading..."}
                </Typography>
                <Typography fontSize={14} fontWeight={500} sx={{ opacity: '60%' }}>
                    {hasNoClubs ? "No Clubs!" : getMemberTypeName(role)}
                </Typography>
            </Stack>
        </Stack>
    </ButtonBase>

}

export default UserProfileDrawerButton