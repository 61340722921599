import DropdownField from "../dropdown-field"


export const genderDropdownValues = [
    {value:"Gender.MALE", label:"M"},
    {value:"Gender.FEMALE", label:"F"},
    {value:"Gender.NONBINARY", label:"Non Binary"}
]

const GenderField = ({label="Gender", value, setValue, readOnly=false, mandatory=false}) => {

        return <DropdownField 
            label={label} 
            value={value} 
            setValue={setValue}
            readOnly={readOnly}
            mandatory={mandatory}
            options={genderDropdownValues}/>

}

export default GenderField