import { IconButton, Tooltip } from "@mui/material"
import { useState } from "react"

const CopyIconButton = ({ value, icon }) => {

    const [copied, setCopied] = useState(false)


    const onClick = () => {
        setCopied(true)
        navigator.clipboard.writeText(value)
    }


    return <Tooltip title={copied ? "Copied to clipboard!" : `${value} (Click to copy)`} onOpen={() => setCopied(false)}>
        <IconButton onClick={onClick}>
            {icon}
        </IconButton>
    </Tooltip>

}

export default CopyIconButton