import { useSelector } from "react-redux"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectEventFromCache, selectUserFirstName, selectUserPersonId } from "../../../redux/selectors"
import { Stack, } from "@mui/material"
import { greenButtonColor, redButtonColor, yellowColor } from "../../../styles/style-constants"
import { useApiUtilsContext } from "../../../providers/api-utils-provider";
import { useEffect } from "react"
import { CalendarIcon } from "@mui/x-date-pickers"
import ButtonComponent from "../../../components/button-component"
import { Check, Close } from "@mui/icons-material"
import { apiPathConfirmAttendee, apiPathDeclineAttendee } from "../../../utils/endpoint-paths"
import { isNotUndefined, isUndefined } from "../../../utils/helper-functions/is-null-or-undefined-or-empty"




const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    setSelectedNotification,
    selected
}) => {
    const confirmed = notification?.metadata?.confirmed
    const name = useSelector(selectUserFirstName)


    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))


    useEffect(() => {
        addEventRequest(notification?.metadata?.event_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => `Hey ${name}, Please Confirm Your Attendance:`}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        image={<CalendarIcon fontSize="large" htmlColor={isUndefined(confirmed) ? yellowColor : (confirmed ? greenButtonColor : redButtonColor)} />}
        getBody={() => <NotificationEventDetailsList event={event} />}

    />

}


const Page = ({ notification, refreshNotifications = () => { } }) => {

    const confirmed = notification?.metadata?.confirmed


    const { generateEndpoint, doPut } = useApiUtilsContext()
    const personId = useSelector(selectUserPersonId)

    const name = useSelector(selectUserFirstName)


    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))

    const confirmEndpoint = generateEndpoint(apiPathConfirmAttendee(event?.id, personId))
    const declineEndpoint = generateEndpoint(apiPathDeclineAttendee(event?.id, personId))


    const onClick = async (confirm) => {
        let endpoint = declineEndpoint
        if (confirm) {
            endpoint = confirmEndpoint
        }
        try {
            await doPut({ endpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }

    const onConfirm = async () => {
        onClick(true)
    }

    const onDecline = async () => {
        onClick(false)
    }


    return <NotificationPageBase
        notification={notification}
        image={<CalendarIcon fontSize="large" htmlColor={isUndefined(confirmed) ? yellowColor : (confirmed ? greenButtonColor : redButtonColor)} />}
        getBodyOpener={() => `Hey ${name},`}
        getBodyText={() => "A new event has been created for your team:"}
        getBody={() => <NotificationEventDetailsList

            event={event}
            showLocation
            showTime
        />}

        getActions={() => <Stack direction={"row"} spacing={2}>
            <ButtonComponent
                disabled={isNotUndefined(confirmed)}
                icon={<Check />}
                title={"Confirm"}
                background={greenButtonColor}
                onClick={onConfirm}
                disabledOpacity={confirmed ? 1.0 : 0.5}
            />
            <ButtonComponent
                disabled={isNotUndefined(confirmed)}
                icon={<Close />}
                title={"Decline"}
                background={redButtonColor}
                onClick={onDecline}
                disabledOpacity={!confirmed ? 1.0 : 0.5}
            />
        </Stack>}
    />

}

const NotificationEventCreated = {
    ListItem,
    Page
}

export default NotificationEventCreated