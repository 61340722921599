import { Stack, Box, Avatar, IconButton } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FieldLabel from "../fields/field-label";
import profilePic from "../../assets/default-profile-2.png";
import { grey } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { length } from "ramda";
import { useApiUtilsContext } from "../../providers/api-utils-provider";

const PhotoBox = ({ minioId, setFormData, setMinioId, label="photo", readOnly = false, disabled=false }) => {

    const {getImageUrl} = useApiUtilsContext()

    const [localImage, setLocalImage] = useState(profilePic)

    const inputRef = useRef(null)

    const onClick = () => {
        inputRef.current.click()
    }

    const onUpload = (fileList) => {

        const fileReader = new FileReader()
        fileReader.onloadend = onRead
        

        if (length(fileList) > 0) {
            const file = fileList[0]
            let data = new FormData()
            data.append('file', file)
            setFormData(data)
            setMinioId(undefined)
            fileReader.readAsDataURL(file)
        }
    }

    const onRead = (e) => {
        setLocalImage(e.target.result)
    }

    useEffect(() => {
        if (minioId) {
            setLocalImage(getImageUrl(minioId))
        } else {
            setLocalImage(profilePic)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[minioId])

    return (
        <Stack direction="column" spacing={2} sx={{
            marginRight: "2rem",
            position: "relative",
            width: "200px"

        }}>
            <input 
                type="file" 
                id="file" 
                accept="image/*"
                max={1}
                onChange={(e) => {
                    onUpload(e.target.files)
                }}
                ref={inputRef} 
                style={{display:"none"}}
            />
            <FieldLabel label={label} disabled={disabled}/>
            <Box
                component="img"
                src={localImage}
                alt="Club Profile"
                sx={{
                    padding: "10px",
                    borderRadius: "100%",
                    objectFit: "cover",
                    height: "200px",
                    opacity: disabled ? 0.5 : 1.0,
                }}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = profilePic
                }}
            />
            {(!readOnly && !disabled) &&
                <IconButton
                    onClick={onClick}
                    sx={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        padding: 0,
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)"
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: grey[500],
                            width: "56px",
                            height: "56px"
                        }}
                    >
                        <AddAPhotoIcon />
                    </Avatar>
                </IconButton>
            }
        </Stack>
    );
};

export default PhotoBox;