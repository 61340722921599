import { has, reduce } from "ramda";
import { statusAccepted, statusList } from "../constants";

const getActivityStatusCounts = (activities) => reduce((acc, activity) => {
    if (has(activity?.status, acc)) {
        acc[activity?.status] += 1
    } else {
        acc[activity?.status] = 1
    }
    return acc
}, {}, activities)

export const getEventStatus = (event) => {
    const countsStatus = getActivityStatusCounts(event?.activities ?? [])

    let itemStatus = statusAccepted
    for (let statusIndex in statusList) {
        const status = statusList[statusIndex]
        if (has(status, countsStatus)) {
            itemStatus = status
            break
        }
    }
    return itemStatus
}

export const getEventStatusCounts = (events) => reduce((acc, event) => {
    const eventStatus = getEventStatus(event)
    if (has(eventStatus, acc)) {
        acc[eventStatus] += 1
    } else {
        acc[eventStatus] = 1
    }
    return acc
}, {}, events)

export default getActivityStatusCounts