import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"

const BaseDialog = ({ open, onClose, title, content, actions, titleProps, actionProps, paperProps}) => { 
    return <Dialog open={open} onClose={onClose} PaperProps={paperProps}>
        <DialogTitle {...titleProps}>{title}</DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions {...actionProps}>
            {actions}
        </DialogActions>
    </Dialog>
}

export default BaseDialog