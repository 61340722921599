import { MenuItem, Select, Stack } from "@mui/material"
import FieldLabel from "../field-label"
import { fieldWidth } from "../../../styles/style-constants"
import ReadOnlyField from "../readonly-field"
import { find, equals } from "ramda"

const DropdownField = ({
    label,
    value,
    setValue,
    options = [],
    spacing = 2,
    disabled = false,
    readOnly = false,
    maxWidth = fieldWidth,
    mandatory = false
}) => {


    const handleChange = event => {
        setValue(event.target.value)
    }

    return <Stack spacing={spacing} maxWidth={maxWidth}>
        <FieldLabel label={label} mandatory={mandatory} />
        {readOnly ?
            <ReadOnlyField value={value ?? ""} parseValue={(option) => find((item) => equals(item?.value)(option))(options)?.label ?? option}/> :
            <Select
                readOnly={readOnly}
                disabled={disabled}
                variant="standard"
                value={value ?? ''}
                onChange={handleChange}
            >
                {options.map(({ label, value }) =>
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                )}
            </Select>}
    </Stack>


}

export default DropdownField