import { useSelector } from "react-redux"
import { NotificationEventDetailsList, NotificationListItemBase, NotificationPageBase } from "../notification-type-base"
import { selectActivityFromCache, selectEventFromCache, selectUserFirstName } from "../../../redux/selectors"
import { Box, Stack } from "@mui/material"
import { redButtonColor } from "../../../styles/style-constants"
import { useEffect } from "react"
import ButtonComponent from "../../../components/button-component"
import { Close } from "@mui/icons-material"
import { useApiUtilsContext } from "../../../providers/api-utils-provider"
import { apiPathDeclineDelegation } from "../../../utils/endpoint-paths"

const getBodyOpener = (name) => `Hey ${name}, `
const getBodyText = () => `You have an upcoming Volunteer Event. See you there!`

const ListItem = ({
    notification,
    markAsRead,
    markAsUnread,
    addEventRequest,
    addActivityRequest,
    setSelectedNotification,
    selected
}) => {

    const name = useSelector(selectUserFirstName)



    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))

    useEffect(() => {



        addEventRequest(notification?.metadata?.event_id)
        addActivityRequest(notification?.metadata?.activity_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return <NotificationListItemBase
        setSelectedNotification={setSelectedNotification}
        selected={selected}
        notification={notification}
        getBodyText={() => getBodyOpener(name) + getBodyText()}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
        getBody={() => <NotificationEventDetailsList
            event={event}
        />}
    />

}



const Page = ({ notification, refreshNotifications }) => {
    const name = useSelector(selectUserFirstName)

    const {generateEndpoint, doPut} = useApiUtilsContext()

    const event = useSelector(selectEventFromCache(notification?.metadata?.event_id))
    const activity = useSelector(selectActivityFromCache(notification?.metadata?.activity_id))

    const declineEndpoint = generateEndpoint(apiPathDeclineDelegation(activity?.id))

    const onDecline = async () => {
        try {
            await doPut({ endpoint: declineEndpoint, queryParams: { notif_id: notification?.id } })
            refreshNotifications()
        } catch (error) {
            console.error(error)
        }
    }


    return <NotificationPageBase
        notification={notification}
        getBodyOpener={() => getBodyOpener(name)}
        getBodyText={() => getBodyText()}
        getBody={() => <NotificationEventDetailsList

            event={event}
            showLocation
            showTime
            activity={activity}
            showActivity
        />}

        getActions={() => <>
            <Box paddingBottom={"5px"}>
                No longer can make it?
            </Box>
            <Stack direction={"row"} spacing={2}>
                <ButtonComponent icon={<Close />} title={"Cancel Attendance"} background={redButtonColor} onClick={onDecline} />
            </Stack>
        </>}
    />

}


const NotificationVolunteerEventReminder = {
    ListItem,
    Page
}

export default NotificationVolunteerEventReminder