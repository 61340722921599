import { createContext, useContext, useEffect, useState } from "react";
import { isNull } from "../utils/helper-functions/is-null-or-undefined-or-empty";

import Keycloak from "keycloak-js";
import { getKeycloakUrl } from "../utils/envs";

export const REALM = "mcclubs"
export const CLIENT_ID = "frontend"




const CustomKeycloakContext = createContext({
    initialised: false
})

const CustomKeycloakProvider = ({ children }) => {


    const [keycloakInstance, setKeycloakInstance] = useState(null)
    const [initialised, setInitialised] = useState(false)


    
 


    const initKeycloak = async () => {

        if (!initialised || isNull(keycloakInstance)) {

            let newKeycloakInstance = new Keycloak({
                url: getKeycloakUrl(),
                realm: REALM,
                clientId: CLIENT_ID,
            });


            let options = {
                checkLoginIframe: false
            }
            
   
            setKeycloakInstance(newKeycloakInstance)
            await newKeycloakInstance.init(options)

            setInitialised(true)

            

        }


    }


    useEffect(() => {

        initKeycloak()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const value = {
        keycloak: keycloakInstance,
        initialised
    }


    return <CustomKeycloakContext.Provider value={value}>
        {initialised && children}
        {!initialised && <div>loading</div>}
    </CustomKeycloakContext.Provider>

}


export default CustomKeycloakProvider

export const useCustomKeycloak = () => useContext(CustomKeycloakContext)