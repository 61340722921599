import { Box, Stack, Typography } from "@mui/material"
import { min } from "ramda"

const ProgressCircleComponent = ({ width = 200, height = 200, hoursCompleted=0, hoursTotal=undefined }) => {

    const hasValue = hoursTotal !== undefined
    const value = min((!hasValue) ? 0.0 : ((hoursTotal === 0) ? 1.0 : hoursCompleted/hoursTotal), 1.0)

    const size = min(width, height) * 1.8

    const angle = Math.PI - Math.PI * value

    const arcEndX = (width / 2) + Math.cos(angle) * (size / 2)
    const arcEndY = height - Math.sin(angle) * (size / 2)


    const cx = `${width / 2}`
    const cy = `${height}`
    const r1 = `${size / 2}`
    const r2 = `${size / 3}`

    const arcStartX = (width / 2) - (size / 2)

    return <Box position="relative">
        
        <svg width={`${width}`} height={`${height}`}>

            <defs>
                <clipPath id="clipPath">
                    <path d={`M ${arcStartX} ${height} A ${r1} ${r1} 0 0 1 ${arcEndX} ${arcEndY} L ${cx} ${cy} L ${arcStartX} ${height} Z`} stroke="#000000" />
                </clipPath>
            </defs>

            <circle cx={cx} cy={cy} r={r1} fill="#DADADA" />
            <circle cx={cx} cy={cy} r={r1} fill="#15AC1D" clipPath="url(#clipPath)" />
            <circle cx={cx} cy={cy} r={r2} fill="#FFFFFF" />




        </svg>
        <Box position={"absolute"} top="0" width="100%" height="100%">
            <Stack width={"100%"} height={"100%"} justifyContent={"flex-end"} alignItems={"center"} paddingBottom={"20px"}>
                <Typography fontSize="48px" textAlign={"center"}>
                    {(value*100).toFixed(1)}%
                </Typography>
            </Stack>
        </Box>
        <Stack direction="row" justifyContent={"center"}>
            <Typography fontSize="18px" fontWeight={600} color="#15AC1D" paddingRight="5px">
                {hoursCompleted}
            </Typography>
            <Typography fontSize="18px">
                out of
            </Typography>
            <Typography fontSize="18px" paddingX="5px" fontWeight={600}>
                {hoursTotal}
            </Typography>
            <Typography fontSize="18px">
                hours completed
            </Typography>
        </Stack>
    </Box>


}

export default ProgressCircleComponent