import { useSelector } from "react-redux"
import { selectClubs, selectCurrentClubRole, selectLoadingData, selectUserAddress, selectUserContactNumber, selectUserDateOfBirth, selectUserEmergencyContact, selectUserFirstName, selectUserLastName } from "../redux/selectors"
import { any, equals, isEmpty, keys } from "ramda"
import { memberTypeAdmin } from "./constants"


export const useIsAdmin = () => {
    const role = useSelector(selectCurrentClubRole)
    return equals(role, memberTypeAdmin)
}

export const useHasNoClubs = () => {
    const loadingClubs = useSelector(selectLoadingData)
    const clubs = useSelector(selectClubs)

    return isEmpty(clubs ?? []) && !loadingClubs
}



export const useInitiallyOnboarded = () => {

    const firstName = useSelector(selectUserFirstName)
    const lastName = useSelector(selectUserLastName)
    const dateOfBirth = useSelector(selectUserDateOfBirth)
    const contactNumber = useSelector(selectUserContactNumber)
    const address = useSelector(selectUserAddress)
    const emergencyContact = useSelector(selectUserEmergencyContact)
    const {name: emergencyContactName, mobile: emergencyContactNumber} = emergencyContact ?? {}

    const strings = [
        firstName, 
        lastName, 
        contactNumber, 
        emergencyContactName, 
        emergencyContactNumber,
        dateOfBirth
    ]
    const objects = [
        address, 
    ]
    

    const anyStrings = any((value) => isEmpty(value ?? ""))(strings)
    
    const anyObjects = any((value) => isEmpty(keys(value ?? {})))(objects)
    return !anyStrings && !anyObjects


}
