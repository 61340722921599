import { Stack, Typography } from "@mui/material";
import BasePageComponent from "../../components/base-page-component";
import CardContainer from "../../components/card-container";
import FormHeader from "../../components/form-header-component";
import ButtonStack from "../../components/buttons/save-cancel-stack";
import { useEffect, useState } from "react";
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathClubMembers, apiPathCreateTeam, apiPathDeleteTeam, apiPathGetTeam } from "../../utils/endpoint-paths";
import { assocPath, equals, find, findIndex, forEach, map, reject, set, update } from "ramda";
import { useSelector } from "react-redux";
import { selectCurrentClubId } from "../../redux/selectors";
import { useSearchParams } from "react-router-dom";
import { useIsAdmin } from "../../utils/user-hooks";
import isAllMembersTeam from "../../utils/helper-functions/is-all-members-team";
import FormTableComponent from "../../components/form-table-component";
import { columnTypeActions, columnTypeString } from "../../components/table-component";
import { dashboardRoute, teamsRoute } from "../../utils/page-routes";
import { DeleteOutlineOutlined, EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import CopyIconButton from "../../components/fields/copy-icon-button";
import useDoNavigate from "../../utils/do-navigate";
import { newFormEntityId } from "../../utils/constants";
import { sortTeamMembersExclusive } from "../../utils/helper-functions/sort-team-members";
import PageActionsStack from "../../components/buttons/page-actions-stack";
import ButtonComponent from "../../components/button-component";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { darkBlueButtonColor, redButtonColor } from "../../styles/style-constants";
import BaseDialog from "../../components/dialog-component";
import CloseIcon from '@mui/icons-material/Close';


const EditTeamPage = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [teamMemberList, setTeamMemberList] = useState([]);
    const [memberList, setMemberList] = useState([])
    const [snackbarLoading, setSnackbarLoading] = useState(false)
    const [imageId, setImageId] = useState()
    const [imageFormData, setImageFormData] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()

    const updateTarget = formEntityId => setSearchParams((prev) => ({...prev, target: formEntityId}))
    const doNavigate = useDoNavigate()
    const isAdmin = useIsAdmin()

    const teamId = searchParams.get("target")
    const isCreatingNew = equals(teamId, newFormEntityId)
    

    const currentClubId = useSelector(selectCurrentClubId)

    const { generateEndpoint, doPost, doGet, doPut, uploadPfp, doDelete } = useApiUtilsContext()

    const getTeamsEndpoint = generateEndpoint(apiPathGetTeam(teamId))
    const createClubEndpoint = generateEndpoint(apiPathCreateTeam(currentClubId))
    const getClubMembersEndpoint = generateEndpoint(apiPathClubMembers(currentClubId))

    const canEdit = isAdmin && !isAllMembersTeam({ details: { name } })


    const getMemberByUuid = (uuid) => {
        return find((value) => equals(value?.member?.uuid ?? value?.uuid)(uuid))(memberList)
    }

    const getTeamMemberIndex = uuid => findIndex((item) => equals(item?.member?.uuid)(uuid))(teamMemberList)

    const mapMembers = (member) => {
        return {
            label: member?.fullName || member?.email,
            id: member?.uuid
        }
    }



    const mapTeamMemberForEndpoint = (member) => {
        return {
            id: member?.id,
            member: {
                id: member?.member?.id,
            },
            position: member?.position ?? "Team Member"
        }
    }

    const mappedMemberList = map(mapMembers)(memberList)

    const onAddTeamMembers = (members) => {
        forEach(onAddTeamMember)(members)
    }

    const onAddTeamMember = (member) => {
        const { id: memberUuid } = member
        const newMember = getMemberByUuid(memberUuid)
        // Maybe some insertion sort here might be better? This works for now and our use case
        setTeamMemberList((prev) => sortTeamMembersExclusive([...prev, { member: newMember, position: "Team Member" }]))
    }

    const onRemoveTeamMember = (member) => {
        const { uuid: memberUuid } = member?.member ?? {}
        setTeamMemberList((prev) => reject((m) => equals(m?.member?.uuid)(memberUuid))(prev))
    }

    const onUpdateTeamMember = (teamMember, path, value) => {
        const teamMemberIndex = getTeamMemberIndex(teamMember?.member?.uuid)
        setTeamMemberList((prev) => update(teamMemberIndex, assocPath(path, value, teamMember))(prev))
    }


    const onSubmit = async () => {
        setSnackbarLoading(true)
        try {

            let pictureId = imageId
            if (imageFormData) {
                pictureId = await uploadPfp(imageFormData)
            }

            const body = {
                details: {
                    name,
                    description,
                    pictureId
                },
                members: map(mapTeamMemberForEndpoint)(teamMemberList ?? [])
            }

            if (isCreatingNew) {
                const response = await doPost({
                    endpoint: createClubEndpoint,
                    body
                })
                const newLocation = response?.headers?.location

                const newItemResponse = await doGet({ endpoint: newLocation })
                updateTarget(newItemResponse?.data?.id)

            } else {
                await doPut({ endpoint: getTeamsEndpoint, body })
            }
        } catch (error) {
            console.error(error)
        }
        setSnackbarLoading(false)
        doNavigate(teamsRoute)

    }

    const onCancel = async () => {
        await getTeamDetails()
        doNavigate(teamsRoute)
    }

    const getTeamDetails = async () => {
        const response = await doGet({ endpoint: getTeamsEndpoint })
        setDescription(response?.data?.details?.description)
        setName(response?.data?.details?.name)
        setTeamMemberList(sortTeamMembersExclusive(response?.data?.members))
        setImageId(response?.data?.details?.pictureId ?? null)
    }

    useEffect(() => {

        const getClubMembers = async () => {
            const response = await doGet({ endpoint: getClubMembersEndpoint })

            setMemberList(map((member) => member?.member)(response?.data))
        }

        if (currentClubId) {
            getClubMembers()
            if (teamId && !isCreatingNew) {
                getTeamDetails()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId, isCreatingNew])

    useEffect(() => {
        if (!teamId) {
            doNavigate(dashboardRoute)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamId])

    const onDelete = async () => {
        const deleteEndpoint = generateEndpoint(apiPathDeleteTeam(teamId));
        try {
            setIsDeleteLoading(true);
            await doDelete({ endpoint: deleteEndpoint })
            setOpenDeleteDialog(false)
            setIsDeleteLoading(false)
            doNavigate(teamsRoute);
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <BasePageComponent
            pageTitle="Teams"
            backRoute={teamsRoute}
            inlineContent={canEdit && <PageActionsStack
                 saveAction={onSubmit} 
                 deleteComponent={
                    <ButtonComponent
                      title={"Delete Team"}
                      icon={<DeleteOutlinedIcon />}
                      background={darkBlueButtonColor}
                      onClick={() => setOpenDeleteDialog(true)}
                    />
                  }
                 cancelAction={onCancel} />}
            snackbarLoading={snackbarLoading}
        >   
            <DeleteTeamDialog 
            openDeleteDialog={openDeleteDialog} 
            setOpenDeleteDialog={setOpenDeleteDialog} 
            handleDelete={onDelete} 
            isDeleteLoading={isDeleteLoading}/>
            <CardContainer padding="20px">
                <Stack spacing={5}>
                    <FormHeader
                        label="Team Name"
                        title={name}
                        setTitle={setName}
                        description={description}
                        setDescription={setDescription}
                        readOnly={!canEdit}
                        setFormData={setImageFormData}
                        minioId={imageId}
                        setMinioId={setImageId}
                    />

                    <FormTableComponent
                        title={"Team Members"}
                        searchItems={mappedMemberList}
                        items={teamMemberList}
                        onAdd={onAddTeamMembers}
                        onRemove={onRemoveTeamMember}
                        onUpdate={onUpdateTeamMember}
                        multiAdd={true}
                        itemEquality={(searchItem, rowItem) => equals(searchItem?.id, rowItem?.member?.uuid)}
                        readOnly={!canEdit}
                        columns={[
                            { title: "Name", getValue: row => row?.member?.fullName || row?.member?.email },
                            {
                                title: "Position",
                                getValue: row => row?.position,
                                canEdit: true,
                                type: columnTypeString,
                                setPath: ["position"],
                                
                            },
                            {
                                title: "Actions", getValue: row => {
                                    return <Stack direction="row" spacing={1}>
                                        <CopyIconButton
                                            value={row?.member?.email}
                                            icon={<EmailOutlined />}
                                        />
                                        {row?.member?.mobile &&
                                            <CopyIconButton
                                                value={row?.member?.mobile}
                                                icon={<PhoneOutlined />}
                                            />
                                        }
                                    </Stack>
                                }, type: columnTypeActions
                            },
                        ]}
                    />


                </Stack>
            </CardContainer>
        </BasePageComponent>
    );
};

export const DeleteTeamDialog = ({
    openDeleteDialog,
    setOpenDeleteDialog,
    handleDelete,
    isDeleteLoading,
  }) => {
    return (
      <BaseDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={"Delete Team?"}
        titleProps={{ textAlign: "center", fontSize: "24px" }}
        content={
          <Stack direction={"column"} alignContent={"center"} gap={"10px"}>
            <Typography
              color={"#3C3352"}
              letterSpacing={"2px"}
              fontSize={"14px"}
              fontWeight={"600"}
              textAlign={"center"}
            >
              Are you sure you want to delete?
            </Typography>
            <Typography
              color={"#3C3352"}
              letterSpacing={"2px"}
              fontSize={"14px"}
              fontWeight={"600"}
              textAlign={"center"}
            >
              (Please note, this action cannot be reversed)
            </Typography>
          </Stack>
        }
        actionProps={{ sx: { justifyContent: "center", marginBottom: "20px" } }}
        paperProps={{
          sx: { border: "1px solid rgba(50, 62, 89, 1)", paddingX: "30px" },
        }}
        actions={
          <Stack direction={"row"} gap={"25px"}>
            <ButtonComponent
              title={"Delete"}
              icon={<DeleteOutlinedIcon fontSize="small" />}
              background={darkBlueButtonColor}
              onClick={handleDelete}
              disabled={isDeleteLoading}
            />
            <ButtonComponent
              disabled={isDeleteLoading}
              title={"Cancel"}
              icon={<CloseIcon fontSize="small" />}
              background={redButtonColor}
              onClick={() => setOpenDeleteDialog(false)}
            />
          </Stack>
        }
      />
    );
};

export default EditTeamPage;